@use 'sass:math';

@import './structure/utils/color';

/* VARIABLES */
@import './themes/generics';
@import './themes/easyid.theme';
@import './themes/satrix.theme';
@import './themes/telkom.theme';
@import './themes/gcash-red.theme';
@import './themes/gcash-blue.theme';

/* UTILS */
@import './structure/utils/reset';
@import './structure/utils/animate';
@import './structure/utils/panel';
@import './structure/utils/focus';
@import './structure/utils/spacing';
@import './structure/utils/media';
@import './structure/utils/hidden';
@import './structure/utils/media';
@import './structure/utils/divider';

/* LAYOUT */
@import './structure/layout/root-layout';
@import './structure/layout/container';
@import './structure/layout/section';
@import './structure/layout/row';
@import './structure/layout/fill';
@import './structure/layout/flex';

@import './structure/layout/page-container.mobile';
@import './structure/layout/page-actions.mobile';

/* TYPOGRAPHY */
@import './structure/typography/text-block';
@import './structure/typography/page-title';
@import './structure/typography/headings';
@import './structure/typography/anchor';
@import './structure/typography/alignment';
@import './structure/typography/font';
@import './structure/typography/color';
@import './structure/typography/text';

/* COMPONENTS */
@import './structure/components/panel';
@import './structure/components/header/header.desktop';
@import './structure/components/header/header.mobile';
@import './structure/components/navigation';
@import './structure/components/mat-select';
@import './structure/components/mat-auto-form-field';
@import './structure/components/button';
@import './structure/components/form';
@import './structure/components/card';
@import './structure/components/alert';
@import './structure/components/alert-info';
@import './structure/components/form-group';
@import './structure/components/form-hint';
@import './structure/components/stepper';
@import './structure/components/form-checkbox-group';
@import './structure/components/form-checkbox';
@import './structure/components/form-radio-group';
@import './structure/components/form-radio';
@import './structure/components/form-checkbox';
@import './structure/components/form-field-colors';
@import './structure/components/mat-calendar';
@import './structure/components/list';
@import './structure/components/hr';
@import './structure/components/selection-cards';
@import './structure/components/message';
@import './structure/components/products';
@import './structure/components/mat-menu';
@import './structure/components/mobile.profile-type-cards';
@import './structure/components/mobile.hint';
@import './structure/components/radio.mobile';
@import './structure/components/svg-icon';
@import './structure/components/cards.mobile';
@import './structure/components/contracting-entity';
@import './structure/components/mat-checkbox';
@import './structure/components/mat-datepicker';
@import './structure/components/coming-soon-tag';
@import './structure/components/help';
@import './structure/components/mat-tooltip';
@import './structure/components/country-dropdown';
@import './structure/components/datepicker';
@import './structure/components/tabs';
@import './structure/components/user-details';
@import './structure/components/validation-message';
@import './structure/components/mat-table';

@import './structure/components/selector.mobile';
@import './structure/components/selector-list.mobile';
@import './structure/components/dialog.mobile';
@import './structure/components/mobile-input-group';
@import './structure/components/mat-form-field.mobile';
@import './structure/components/maintenance';
@import './structure/components/current-user';
@import './structure/components/linked-account';
@import './structure/components/header/private-header.desktop';
@import './structure/components/menu-item';
@import './structure/components/menu-collapsed';
@import './structure/components/action-tiles';
@import './structure/components/mat-drawer';
@import './structure/components/table';
@import './structure/components/notes';
@import './structure/components/document-uploads';
@import './structure/components/dialog';
@import './structure/components/datepicker';
@import './structure/components/data-grid.mobile';
@import './structure/components/clickable';

/* --- DISABLE ZOOM ON IPHONE ---*/
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
  input[type='text']:focus,
  input[type='password']:focus,
  textarea:focus,
  select:focus {
    font-size: 16px;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;

  &::selection {
    background: var(--eid-col-accent);
    color: var(--eid-col-accent-contrast);
  }
}

html {
  overflow-y: scroll;
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  @include for-handset-only() {
    overflow: hidden;
  }
}

body {
  font-size: 16px;
  font-family: var(--eid-base-font);
  color: var(--eid-base-text-col);
  background: var(--eid-col-xlight-gray);
  touch-action: pan-x pan-y;
}

ng-component {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin: 0;
}

strong {
  font-weight: 600;
}

sup {
  vertical-align: super;
  font-size: 0.75em;
}

.pac-container:empty {
  display: none;
}

.theme {
  &--satrix {
    eid-initialize {
      min-height: 450px;
      position: relative;
      background: rgba(0, 0, 0, 0);
    }
    eid-authorize {
      min-height: 450px;
      position: relative;
      background: rgba(0, 0, 0, 0);
    }
  }
}
