.message {
  width: 100%;
  margin: 1.5rem auto;
  padding: 0;

  @media screen and (min-width: 769px) {
    width: 90%;
    margin: 3.125rem auto;
    padding: 2.5rem;
  }

  .row {
    justify-content: flex-start;
    align-items: center;

    &.align-top {
      align-items: flex-start;
    }

    @media screen and (max-width: 768px) {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1.5rem 1.5rem 2.25rem 1.5rem;
    }
  }
  &__icon {
    flex: 0 0 120px;
    max-width: 120px;
    height: 120px;

    margin-right: 2.8125rem;

    @media screen and (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 2.8125rem;
    }
  }
  &__text {
    flex: 1 1 auto;

    h2 {
      margin-top: 0;
      margin-bottom: 0.75rem;

      font-size: 1.5rem;

      &:not(:first-child) {
        margin-top: 2rem;
      }

      @media screen and (max-width: 768px) {
        text-align: center;
      }

      @media screen and (min-width: 769px) {
        margin-top: 0;
        margin-bottom: 0.5rem;

        font-size: 1.375rem;
      }
    }
    p {
      margin-top: 0;
      margin-bottom: 0.75rem;
      line-height: 1.375rem;

      font-size: 0.875rem;
      font-family: var(--eid-base-font);

      @media screen and (max-width: 768px) {
        text-align: center;
      }
      @media screen and (min-width: 769px) {
        margin-bottom: 1rem;
        line-height: 1.625rem;

        font-size: 1.125rem;
        font-family: var(--eid-accent-font);
      }
    }
  }
}
