.mat-form-field-required-marker {
  display: none;
}

.mat-auto-form-field {
  width: 100%;
  padding-bottom: 1rem;
  .mat-form-field-wrapper {
    padding-bottom: 0;
    .mat-form-field-flex {
      align-items: flex-end;
      .mat-form-field-infix {
        display: flex;
        flex-direction: column;
        padding: 0;
        border: 0;
        .mat-input-element,
        .mat-select {
          order: 1;
          height: var(--comp-select-height);
          border: 1px solid var(--eid-border-color);
          box-sizing: border-box;
          border-radius: 8px 0 0 8px;
          padding: 0 1rem;
          cursor: pointer;
          color: var(--eid-value-text-col);
          font-size: 0.875rem;
          font-family: var(--eid-base-font);

          &:disabled {
            background-color: var(--eid-col-xlight-gray);
            cursor: default;
          }
        }
        .mat-form-field-label-wrapper {
          order: 0;
          position: static;
          top: auto;
          padding: 0 0 0.5rem 0;
          .mat-form-field-label {
            position: static;
            top: auto;
            transform: none;
            width: 100%;
            mat-label {
              white-space: normal;
              font-family: var(--eid-base-font);
              font-size: 0.875rem;
              color: var(--eid-base-text-col);
            }
          }
        }
      }
      .mat-form-field-suffix {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 40px;
        min-width: 40px;
        max-width: 40px;
        width: 40px;
        min-height: 40px;
        max-height: 40px;
        height: 40px;

        border: 1px solid var(--eid-border-color);
        border-left: none;
        border-radius: 0 8px 8px 0;
        cursor: pointer;
      }
    }
    .mat-form-field-underline {
      background: none;
      .mat-form-field-ripple {
        display: none;
      }
    }
    .mat-form-field-subscript-wrapper {
      position: static;
      margin-top: 0.5rem;
      top: auto;
      font-size: 0.75rem;
      font-family: var(--eid-base-font);

      .mat-error {
        font-size: 0.875rem;
        padding-bottom: 0.25rem;
        color: var(--eid-col-error);
      }
    }
  }

  &.mat-focused {
    .mat-select {
      @include focus();
    }
    .mat-select-arrow {
      color: var(--eid-col-mid-gray);
    }
  }

  &--no-label {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-form-field-label-wrapper {
            display: none;
          }
        }
      }
    }
  }
}
.mat-form-field-disabled {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-suffix {
        background-color: var(--eid-col-xlight-gray);
        cursor: default;
      }
    }
  }
}

.mat-select-panel-wrap {
  .mat-select-panel {
    max-width: none;
    .mat-option {
      line-height: 48px;
      height: 48px;
      font-size: 0.875rem;
      font-family: var(--eid-base-font);
      color: var(--eid-base-text-col);
      &.mat-selected {
        color: var(--eid-col-accent);
        &:not(.mat-option-multiple) {
          background: var(--eid-col-xlight-gray);
        }
      }
      &.has-underline {
        border-bottom: 1px solid var(--eid-border-color);
      }
    }
  }
}
