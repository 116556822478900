.document-uploads {
  .document {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    margin-left: 1rem;

    .mat-icon {
      --size: 4rem;
      --icon-size: calc(var(--size) * 0.65);

      display: flex;
      justify-content: center;
      align-items: center;

      width: var(--size);
      min-width: var(--size);
      height: var(--size);

      margin-right: 1rem;
      border-radius: var(--size);
      box-shadow: var(--eid-box-shadow);

      line-height: var(--size);
      font-size: var(--icon-size);

      svg {
        width: var(--icon-size);
        height: var(--icon-size);
      }
    }
    .description {
      flex: 1 1 auto;
      h4 {
        margin-bottom: 1rem;
      }
      p {
        font-size: 0.875rem;
        color: var(--eid-col-mid-gray);
        line-height: 1.2;
      }
      .btn-solid {
        margin-top: 1rem;
        margin-bottom: 2rem;
      }
    }
  }
}
