.coming-soon-tag {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 10rem;
  height: 2.25rem;
  margin: 0 auto 1rem auto;
  padding: 0 1rem;
  border-radius: 0.5rem;

  font-family: var(--eid-accent-font);
  font-size: 1rem;
  color: var(--eid-col-light);

  @media (min-width: 320px) and (max-width: 768px) {
    border-radius: 6.25rem;
    width: 8rem;
    height: 2rem;
    font-size: 0.75rem;
    padding: 0 0.5rem;
    margin: 0;
  }
  @media (min-width: 280px) and (max-width: 319px) {
    font-size: 0.75rem;
    padding: 0 5px;
    width: 7rem;
    margin: 0;
    .mat-icon {
      margin-right: 5px;
    }
  }

  &--coming-soon {
    background-color: var(--eid-col-coming-soon);
    @media (min-width: 320px) and (max-width: 768px) {
      span {
        text-transform: uppercase;
      }
    }
  }
  &--kids {
    background-color: var(--comp-coming-soon-bg--kids);
  }
  &--company {
    background-color: var(--comp-coming-soon-bg--company);
  }

  .mat-icon {
    width: 18px;
    height: 18px;

    margin-right: 0.75rem;

    @media (min-width: 320px) and (max-width: 768px) {
      width: 13px;
      height: 13px;
      margin-right: 0.313rem;
    }
    @media (min-width: 280px) and (max-width: 319px) {
      margin-right: 5px;
    }
  }
}
