.form-checkbox {
  display: block;
  position: relative;
  margin-left: 1.25rem;
  flex: 0 0 auto;

  &:first-child {
    margin-left: 0;
  }
  input {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    opacity: 0;

    &:checked {
      & + .form-checkbox__button {
        .box {
          background: var(--eid-col-accent);
          border-color: var(--eid-col-accent);

          &::after {
            border-left: 2px solid var(--eid-col-light);
            border-bottom: 2px solid var(--eid-col-light);
          }
        }
        .label {
        }
      }
    }
  }
  .form-checkbox__button {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    @include animate(border background);

    .box {
      position: relative;
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 0.5rem;
      border-radius: var(--eid-border-radius);
      border: 1px solid var(--eid-border-color);
      background: var(--eid-col-light);

      @include animate(border);

      &::after {
        content: '';
        display: block;
        width: 9px;
        height: 4px;
        border-left: 2px solid var(--eid-col-light);
        border-bottom: 2px solid var(--eid-col-light);
        transform: rotate(-45deg) translate(-1px, 5px);
      }
    }
    .label {
      font-size: 0.875rem;
      font-weight: 400;
      color: var(--eid-col-mid-gray);

      @include animate(color);
    }
  }

  &--mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 1.25rem 0;
  }
}
