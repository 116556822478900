.btn-solid {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  min-width: 9rem;
  height: var(--comp-button-height);
  margin: 0;
  padding: 0 1.5rem;
  border: none;
  border-radius: 8px;
  background: none;

  font-family: var(--eid-base-font);
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: -0.34px;

  outline: none;
  cursor: pointer;

  &--primary {
    background: var(--eid-col-accent);
    color: var(--eid-col-accent-contrast);

    &:hover {
      background: var(--eid-col-accent-hover);
      text-decoration: none;
    }
    &:active {
      background: var(--eid-col-accent-active);
    }
    &:focus {
      box-shadow: 0 0 0px 3px var(--eid-col-accent-focus);
    }
  }
  &--secondary {
    border: 1px solid var(--eid-col-mid-gray);
    color: var(--eid-col-mid-gray);
    background-color: var(--eid-col-light);
  }
  &--mid-blue {
    border-radius: var(--eid-form-border-radius);
    color: var(--eid-col-light);
    background-color: var(--eid-col-mid-blue);
  }
  &--link {
    color: var(--eid-col-mid-blue);
  }

  &--rounded {
    border-radius: 8px;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    width: 0;
    height: 0;
    margin-right: 0;

    transform: scale(0);

    @include animate(transform margin);
  }

  &--loading {
    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      transform: scale(1);
    }
  }

  &--small {
    --comp-button-height: 2rem;
    padding: 0 1rem;
    font-size: 0.75rem;
  }
}

.btn-rounded {
  border-radius: var(--eid-button-border-radius);

  font-size: 0.875rem;
  font-weight: 600;

  //height: 40px;
  &--no-border {
    border: none;
    color: var(--eid-col-accent);
  }
}

.btn-outline {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  min-width: 7rem;
  height: var(--comp-button-height);
  margin: 0;
  padding: 0 1.5rem;
  border: 2px solid currentColor;
  border-radius: 2px;
  background: none;

  font-family: var(--eid-base-font);
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: -0.34px;
  text-decoration: none !important;

  outline: none;
  cursor: pointer;

  @include animate(border background color);

  svg {
    width: 0;
    height: 0;
    margin-right: 0;

    transform: scale(0);

    @include animate(transform margin);
  }

  &--loading {
    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      transform: scale(1);
    }
  }

  &--small {
    --comp-button-height: 2rem;
    padding: 0 1rem;
    font-size: 0.75rem;
    border-width: 1px;
  }

  &--gray {
    color: var(--eid-col-mid-gray);
    border-color: var(--eid-col-mid-gray);

    svg {
      stroke: var(--eid-col-mid-gray);
    }

    &:hover {
      color: var(--eid-col-light);
      border-color: var(--eid-col-accent);
      background: var(--eid-col-accent);

      svg {
        stroke: var(--eid-col-light);
      }
    }
    &:active {
      color: var(--eid-col-light);
      border-color: var(--eid-col-accent);
      background: var(--eid-col-accent-active);
    }
    &:focus {
      @include focus();
    }
  }

  &--green {
    color: var(--gray);
    border-color: var(--eid-col-info);
    border-radius: 8px;
    width: 100%;
    background-color: var(--eid-background-color);
    font-family: var(--eid-accent-font);
    font-size: 1rem;
    cursor: default;
    .mat-icon {
      height: 20px;
      width: 20px;
      font-size: 20px;
      color: var(--eid-col-info);
      margin-right: 5px;
    }
  }

  &--info {
    color: var(--eid-col-gray);
    border-color: var(--eid-col-info);
    border-width: 1px;
    border-radius: 8px;
    width: 100%;
    height: 67px;
    font-family: var(--eid-base-font);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.21px;
    padding: 0.75rem 0.5rem 1rem 0.5rem;
    align-items: flex-start;

    cursor: default;
    .mat-icon {
      height: 20px;
      width: 40px;
      font-size: 22px;
      color: var(--eid-col-info);
      margin-right: 8px;
    }
  }

  &--light {
    color: var(--eid-col-light);
    border-color: var(--eid-col-light);

    svg {
      stroke: var(--eid-col-light);
    }

    &:hover {
      color: var(--eid-col-light);
      border-color: var(--eid-col-accent);
      background: var(--eid-col-accent);

      svg {
        stroke: var(--eid-col-light);
      }
    }
    &:active {
      color: var(--eid-col-light);
      border-color: var(--eid-col-accent);
      background: var(--eid-col-accent-active);
    }
    &:focus {
      @include focus();
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.75;
  }
}

.btn-icon {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  min-width: 7rem;
  height: var(--comp-button-height);
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 2px;
  background: none;

  &--outline {
    border: solid 2px var(--eid-col-semi-gray);
  }

  font-family: var(--eid-base-font);
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: -0.34px;

  outline: none;
  cursor: pointer;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 1.625rem;
    height: 1.625rem;

    background: var(--eid-col-accent);
    color: var(--eid-col-light);

    .mat-icon {
      --size: 1rem;
      width: var(--size);
      height: var(--size);
      line-height: var(--size);
      font-size: var(--size);
    }
  }
  .label {
    margin-left: 0;
    padding-left: 0.75rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--eid-base-text-col);
  }

  &:focus {
    .icon {
      @include focus(var(--eid-col-accent-focus));
    }
  }
}
