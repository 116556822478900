.selector {
  &-label {
    color: var(--eid-col-gray);
  }
  &-value {
    color: var(--eid-value-input-color);
    -webkit-text-fill-color: var(--eid-value-input-color);
  }
}
/* --- Disabled Dropdown Fields --- */
.disabled {
  .selector {
    &-label {
      color: var(--eid-col-mid-gray);
    }
    &-value {
      color: var(--eid-col-mid-gray);
      -webkit-text-fill-color: var(--eid-col-mid-gray);
    }
  }
}

/* --- Input Fields --- */
.form-group {
  &__control {
    color: var(--eid-value-text-col);
    -webkit-text-fill-color: var(--eid-value-text-col);

    &[disabled] {
      color: var(--eid-col-mid-gray);
      -webkit-text-fill-color: var(--eid-col-mid-gray);
    }
  }
}
.mat-form-field {
  &--mobile {
    .mat-input-element {
      color: var(--eid-value-input-color);
    }
  }
  .mat-select-value {
    color: var(--eid-col-mid-gray);
  }
}
.custom-field {
  &--label {
    color: var(--eid-col-gray);
  }
  &--input {
    .mat-input-element {
      color: var(--eid-value-input-color);
      opacity: 1;

      &:disabled {
        color: var(--eid-col-mid-gray);
        -webkit-text-fill-color: var(--eid-col-mid-gray);
        opacity: 1;
      }
    }
  }
}
