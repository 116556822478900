.mat-table {
  border: 1px solid var(--eid-border-color);
  font-family: var(--eid-base-font);

  .mat-header-row {
    height: 2.5rem;
    min-height: 2.5rem;
    .mat-header-cell {
      border: none;

      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;

      vertical-align: middle;

      color: var(--eid-col-gray);
      background: var(--eid-col-xlight-gray);
    }
  }
  .mat-row {
    height: 5.3rem;
    border-top: 1px solid var(--eid-border-color);
    border-bottom: none;

    &.expandable-row {
      min-height: 0;
      height: auto;
      border-top: none;
    }

    .mat-cell {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border: none;
      vertical-align: middle;
    }
  }
}
