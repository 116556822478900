a {
  color: var(--eid-col-accent);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  &:focus {
    @include focus(var(--eid-col-accent-focus));
  }
}
