.form-radio {
  display: block;
  position: relative;
  margin-left: 1.25rem;
  flex: 0 0 calc((50% - (1.25rem + 1.25rem)) / 2);
  max-width: calc((50% - (1.25rem + 1.25rem)) / 2);
  min-width: 6rem;

  &:first-child {
    margin-left: 0;
  }
  input {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    opacity: 0;

    &:checked {
      & + .form-radio__button {
        border-color: var(--eid-col-accent);
        background: var(--eid-col-accent);
        color: var(--eid-col-accent-contrast);

        .box {
          border-color: var(--eid-col-light);
          background: var(--eid-col-light);

          &::after {
            border-left: 2px solid var(--eid-col-accent);
            border-bottom: 2px solid var(--eid-col-accent);
          }
        }
        .label {
          color: var(--eid-col-light);
        }
      }
    }
    &:focus {
      & + .form-radio__button {
        box-shadow: 0 0 0px 3px var(--eid-col-accent-focus);
      }
    }
  }
  .form-radio__button {
    display: flex;
    justify-content: center;
    align-items: center;

    height: var(--comp-input-height);

    border: 1px solid var(--eid-border-color);
    border-radius: var(--eid-max-border-radius);
    background: var(--eid-col-accent-transparent);
    user-select: none;

    @include animate(border background);

    .box {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
      border-radius: 20px;
      border: 1px solid var(--eid-border-color);
      background: var(--eid-col-light);

      @include animate(border);

      &::after {
        content: '';
        display: block;
        width: 9px;
        height: 4px;
        border-left: 2px solid var(--eid-col-light);
        border-bottom: 2px solid var(--eid-col-light);
        transform: rotate(-45deg) translate(-1px, 6px);
      }
    }
    .label {
      font-size: 0.875rem;
      font-weight: 400;
      color: var(--eid-base-text-col);

      @include animate(color);
    }
  }
}
