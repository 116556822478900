.notes {
  .note {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-size: 0.75rem;
    color: var(--eid-col-mid-gray);

    span {
      padding: 0.2rem 0;
      &:first-child {
        flex: 0 0 2rem;
      }
      &:only-child {
        flex: 1 1 auto;
      }
    }
  }
}
