.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(32px, 0, 0) !important;
}

.mat-slide-toggle-thumb {
  width: 28px !important;
  height: 28px !important;
  transform: translate(0%, 12%);
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.05)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.05));
}

.mat-slide-toggle-bar {
  border-radius: 15px !important;
  height: 31px !important;
  width: 60px !important;
  border: 1px solid #dce0e0;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #e7fbf8;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #ffffff;
}

.mat-slide-toggle-bar {
  background-color: #ffffff;
}

.mat-slide-toggle-thumb {
  background-color: #ffffff;
}

.mat-slide-toggle .mat-slide-toggle-ripple {
  display: none;
}

.mat-slide-toggle {
  &.mat-checked {
    .mat-slide-toggle-bar::after {
      content: url('../../../assets/icons/engagement/blur.svg');
      width: 16px;
      height: 13px;
      position: absolute;
      left: 10px;
      top: 4px;
    }
  }
  &:not(.mat-checked) {
    .mat-slide-toggle-bar::after {
      content: url('../../../assets/icons/engagement/blur-no.svg');
      width: 16px;
      height: 13px;
      position: absolute;
      left: 33px;
      top: 4px;
    }
  }
}
