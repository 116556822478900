eid-root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

.eid-root-layout {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  width: 100%;
  height: 100%;

  .root-page {
    display: block;
    flex: 1 1 auto;
  }

  .eid-root-layout-desktop {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .desktop-page-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      width: 100%;
      height: 100%;
      .page-container {
        height: 100%;
        display: flex;
        width: 100%;
        position: relative;
        flex-direction: column;
      }
    }
    .eid-themed-header {
      flex: 0 0 auto;
    }
    .eid-header-desktop {
      flex: 0 0 80px;
    }
    .root-page {
      flex: 1 1 auto;
    }
  }
}

.eid-root-layout-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;

  eid-header-mobile {
    flex: 0 0 60px;
  }
  .root-page {
    height: 100%;
  }
}

//@media screen and (max-device-width: 320px) and (max-device-width: 360px) {
.theme {
  &--gcash {
    .eid-root-layout-mobile {
      .mat-drawer-container {
        background: var(--eid-col-xlight-gray);
      }
    }
  }
}
//}
