.data-grid-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    padding: 1.5rem 0;
    border-top: 1px solid var(--eid-border-color);

    &:first-child {
      padding-top: 0;
      border-top: none;
    }
    .row {
      min-height: 50px;
      align-items: center;
      .col {
        &:first-child {
        }
        &:last-child {
          text-align: right;
        }
      }
    }

    .selector {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }
}
