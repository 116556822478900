.list {
  &--bullet {
    display: block;
    margin: 0;
    padding: 0 0 0 1.2rem;
    list-style: disc;

    li {
      font-size: 0.875rem;
      color: var(--eid-col-mid-gray);
      line-height: 1.25;
    }
  }
  &--numbered {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;

    counter-reset: number;

    li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      margin-top: 1.875rem;

      font-size: 0.875rem;
      color: var(--eid-col-mid-gray);
      line-height: 1.25;

      &:first-child {
        margin-top: 0;
      }

      &::before {
        display: flex;
        justify-content: center;
        align-items: center;

        flex: 0 0 30px;
        max-width: 30px;
        width: 30px;
        height: 30px;
        margin-right: 1.25rem;

        border-radius: 30px;
        border: 1px solid var(--eid-base-text-col);
        color: var(--eid-base-text-col);

        font-size: 0.875rem;
        font-weight: 700;

        counter-increment: number;
        content: counter(number);
      }
    }

    &--mobile {
      li {
        &::before {
          width: 1rem;
          max-width: 1rem;
          height: auto;

          margin-right: 0.5rem;
          border: none;
        }
      }
    }
  }
  &--contact-details {
    margin-top: 1.5rem;
    li {
      margin: 1.5rem 0 2.8rem 0;

      &:first-child {
        margin-top: 0;
      }

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--eid-col-gray);
        text-decoration: none;
      }

      .icon {
        --contact-list-icon-size: 44px;
        display: block;
        width: var(--contact-list-icon-size);
        height: var(--contact-list-icon-size);
        margin-right: 1rem;

        border-radius: var(--contact-list-icon-size);

        background: none;
      }
      .details {
        font-family: var(--eid-accent-font);
        font-size: 1.125rem;
        line-height: 24px;

        .description {
          font-size: 0.875rem;
          color: var(--eid-col-accent);
        }
      }
    }
  }
}
