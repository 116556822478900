.stepper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  margin-bottom: 0;
  border-bottom: none;

  @include for-handset-up {
    border-bottom: 1px solid var(--eid-border-color);
    margin-bottom: 1.5rem;
  }

  &__line {
    flex: 1 1 auto;

    background: var(--eid-border-color);
    height: 1px;

    margin: 0 -1.5rem;
    margin-top: -1.3rem;

    @include for-handset-up {
      margin: 0;
      margin-top: 0;
    }
  }
  &__step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;

    height: 84px;
    padding: 0 0.5rem;

    text-decoration: none !important;
    outline: none;

    @media screen and (min-width: 992px) {
      display: flex;
      flex-direction: row; //
      justify-content: flex-start; //
      align-items: center;
      flex: 0 0 auto; //

      height: 84px;
      padding: 0 1.5rem; //

      text-decoration: none !important;
      outline: none;
    }

    .state {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0;
      margin-bottom: 0.5rem;
      border-radius: 1.5rem;

      background: var(--comp-stepper-color-transparent);
      border: 1px solid var(--comp-stepper-color);
      color: var(--comp-stepper-color);

      font-size: 0.75rem;
      font-weight: 600;

      @include animate(color background);

      @media screen and (min-width: 992px) {
        margin-right: 1rem;
        margin-bottom: 0;
      }
    }
    .label {
      font-family: var(--eid-accent-font);
      font-size: 1rem;
      font-weight: 400;
      color: var(--eid-base-text-col);
      text-align: center;

      @include for-handset-up {
        padding-top: 0.5rem;
      }
    }

    @media screen and (min-width: 992px) {
      .label {
        padding-top: 0;
      }
    }

    &:focus {
      @include focus(var(--comp-stepper-color-focus));
    }
    &.stepper__step--active,
    &.stepper__step--complete {
      .state {
        background: var(--comp-stepper-color);
        color: var(--eid-col-accent-contrast);
      }
    }
  }
}
