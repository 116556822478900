.eid-radio-mobile {
  position: relative;
  width: 100%;
  height: 50px;

  > input {
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;

    &:checked {
      & + label {
        .lock-container {
          display: inline;
          transform: scale(1);
          padding-right: 1.1rem;
          margin-right: 1.1rem;
        }

        .lock {
          display: inline;
          position: absolute;
          top: 14px;
          font-size: 20px;
          -webkit-text-fill-color: var(--eid-col-mid-gray);
          color: var(--eid-col-mid-gray);
        }

        .mat-icon {
          transform: scale(1);
          color: var(--eid-col-accent);
        }
      }
    }
    &:focus {
      & + label {
        background: var(--eid-col-accent-transparent-1);
      }
    }
  }
  label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 100%;
    padding: 0 1rem;

    background: var(--eid-col-accent-transparent);

    @include animate(background);

    @media (min-width: 280px) and (max-width: 319px) {
      .eid-radio-mobile {
        &__label {
          font-size: 0.875rem;
        }
      }
    }

    .label {
      font-size: 1rem;
      font-weight: 400;
      color: var(--eid-base-text-col);
    }
    .mat-icon {
      transform: scale(0);

      @include animate();
    }

    .lock-container {
      transform: scale(0);
    }

    .lock {
      display: none;
    }
  }
}
