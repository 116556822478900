.selection-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  width: 100%;

  .card {
    position: relative;
    flex: 0 0 calc((100% - 6rem) / 3);
    margin-bottom: 3rem;

    border: 1px solid var(--eid-border-color);
    box-shadow: none;

    &:nth-child(3n-2) {
      margin-right: 3rem;
    }
    &:nth-child(3n) {
      margin-left: 3rem;
    }

    &::before {
      display: block;
      height: 0;
      padding-top: 87.5%;
      content: '';
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      .label {
        font-family: var(--eid-accent-font);
        font-size: 1.125rem;
        margin-bottom: 1.325rem;
      }
      .icon {
        display: block;
        width: 55px;
        height: 55px;
        min-height: 55px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        &--passport {
          background-image: url('../../../assets/icons/passport.png');
        }
        &--driverslicense {
          background-image: url('../../../assets/icons/driverslicense.png');
        }
        &--idcard {
          background-image: url('../../../assets/icons/idcard.png');
        }
        &--agecard {
          background-image: url('../../../assets/icons/agecard.png');
        }
        &--other {
          background-image: url('../../../assets/icons/other.png');
        }
      }
    }
    &__verified {
      position: absolute;
      right: 0.75rem;
      bottom: 0.75rem;

      color: var(--eid-col-info);
    }
  }
}
