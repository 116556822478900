.alert {
  --alert-color: var(--eid-col-info);
  --alert-background: var(--eid-col-info-transparent-1);

  display: block;
  width: 100%;

  padding: 1.25rem;
  margin-bottom: 1.875rem;
  border-radius: 2px;

  border: 1px solid var(--alert-color);
  background: var(--alert-background);
  color: var(--alert-color);

  text-align: center;

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.875rem;
    font-size: 0.875rem;
    font-weight: 600;

    .mat-icon {
      margin-right: 0.5em;
    }

    &--center {
      justify-content: center;
    }
  }
  p {
    margin-top: 0.875rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;

    &:first-child {
      margin-top: 0;
    }

    a {
      font-weight: 500;
      color: var(--alert-color);
      text-decoration: none;
      text-decoration: underline;

      outline: none;

      &:focus {
        @include focus(var(--alert-background));
      }
    }
  }

  &--default {
    --alert-color: var(--eid-col-gray);
    --alert-background: var(--eid-col-xlight-gray);
    border-color: var(--eid-border-color);
  }
  &--info {
    --alert-color: var(--eid-col-info);
    --alert-background: var(--eid-col-info-transparent-1);
    color: var(--eid-col-gray);
  }

  &--duplicate-account {
    background: none;
    border-color: var(--eid-col-accent);
    color: var(--eid-col-gray);

    text-align: left;

    .alert__title {
      font-size: 1.375rem;
      font-weight: 400;
      .mat-icon {
        color: var(--eid-col-accent);
      }
    }
    p {
      a {
        color: var(--eid-col-accent);
        text-decoration: none;
      }
    }
  }
}
