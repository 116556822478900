.section {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &--hero {
    height: 375px;
    overflow: hidden;
  }

  &--home-page {
    background-image: var(--eid-home-bg);
    min-height: 644px;
    @media (min-width: 320px) and (max-width: 768px) {
      min-height: 470px;
      background-position-x: 19%;
      height: 470px;
      position: relative;
      //top: 60px;
      margin-bottom: 3rem;
    }
    @media screen and (min-width: 280px) and (max-width: 319px) {
      min-height: 400px;
    }
  }

  &--padded {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  &--bg {
    &-light {
      background: var(--eid-col-light);
    }
    &-gray {
      background: var(--eid-col-xlight-gray);
    }
    &-dark {
      background: var(--eid-col-dark);
    }
    &-darker {
      background: var(--eid-col-darker);
    }

    &-create-id {
      background-image: var(--eid-hero-bg);
    }
    &-login {
      height: 450px;
      background-image: var(--eid-login-hero-bg);
    }
    &-maintenance {
      background: var(--comp-maintenance-color);

      .container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        padding-left: 23%;
        background: var(--comp-maintenance-image) no-repeat;
        background-size: contain;

        @media screen and (max-width: 991px) {
          padding-left: 40%;
        }
        @media screen and (max-width: 767px) {
          padding-left: 0;
          text-align: center;
          background: none;
        }

        .hero__content {
          color: var(--eid-col-light);

          @media screen and (max-width: 767px) {
            padding: 0 1.5rem;
          }

          h1 {
            font-family: var(--eid-accent-font);
            font-size: 3.125rem;

            margin-bottom: 1rem;
          }
          p {
            font-size: 1.125rem;
          }
        }
      }
    }
    &-error {
      background: var(--comp-error-color);

      .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        background: var(--comp-error-image) no-repeat;
        background-size: contain;

        @media screen and (max-width: 767px) {
          padding-left: 0;
          text-align: center;
          justify-content: center;
          background: none;
          flex-direction: column;
        }

        .hero__content {
          color: var(--eid-col-light);

          @media screen and (max-width: 767px) {
            padding: 0 1.5rem;
          }

          h1 {
            font-family: var(--eid-accent-font);
            font-size: 3.125rem;

            margin-bottom: 1rem;

            @media screen and (max-width: 999px) {
              font-size: 2.5rem;
            }
          }
          p {
            font-size: 1.125rem;
          }
        }
      }
    }
  }

  &__title {
    margin-top: 2rem;
    font-family: var(--eid-accent-font);
    font-size: 1.875rem;
    line-height: 1.15;

    text-align: center;

    color: var(--eid-col-light);
  }
}
.maintenance {
  @media (min-width: 280px) and (max-width: 319px) {
    padding-top: 0;
  }
}
