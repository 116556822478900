@mixin for-handset-only {
  @media screen and (max-width: 599.98px) and (orientation: portrait),
    screen and (max-width: 959.98px) and (orientation: landscape) {
    @content;
  }
}

@mixin for-handset-up {
  @media screen and (min-width: 600px) and (orientation: portrait),
    screen and (min-width: 960px) and (orientation: landscape) {
    @content;
  }
}
