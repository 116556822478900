.eid-tabs {
  .mat-tab-header {
    border: none;
    margin-bottom: 2.5rem;
    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels {
          .mat-tab-label {
            height: 50px;
            background: var(--eid-col-light);
            border-top: 1px solid var(--eid-col-light-gray);
            border-bottom: 1px solid var(--eid-col-light-gray);
            border-right: 1px solid var(--eid-col-light-gray);

            font-family: var(--eid-base-font);
            font-weight: 400;

            color: var(--eid-col-mid-gray);

            opacity: 1;

            &:first-child {
              border-left: 1px solid var(--eid-col-light-gray);
            }
            &.mat-tab-label-active {
              background: var(--eid-col-accent);
              color: var(--eid-col-light);
            }

            .mat-tab-label-content {
            }
          }
        }
        .mat-ink-bar {
          display: none !important;
        }
      }
    }
  }
}
