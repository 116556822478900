h2 {
  margin-bottom: 2.5rem;
  font-size: 1.375rem;
  font-family: var(--eid-accent-font);
}
h3 {
  margin-bottom: 0.625rem;
  font-size: 1.125rem;
  font-family: var(--eid-accent-font);
}
h4 {
  font-family: var(--eid-accent-font);
  font-size: 1.125rem;
}
h6 {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  font-family: var(--eid-base-font);
}
