.alert-info {
  --icon-size: 1rem;
  --background: var(--eid-col-mid-gray-transparent-1);
  --color: var(--eid-col-mid-gray);

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 0.4rem 0.7rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.125rem;

  background: var(--background);
  color: var(--color);

  .mat-icon {
    width: var(--icon-size);
    height: var(--icon-size);
    margin-right: 0.5rem;
    line-height: var(--icon-size);
    font-size: var(--icon-size);
  }

  &--success {
    --background: var(--eid-col-info-transparent-1);
    --color: var(--eid-col-info);
  }
  &--error {
    --background: var(--eid-col-accent-transparent-1);
    --color: var(--eid-col-error);
  }
}
