$colors: (
  accent: #ed1747,
  accent-light: #fde8ed,
  info: #0ddaba,
  error: #ea1f48,
  dark: #000000,

  purple: #5308b2,
  mid-gray: #86888b,

  warning: #f4942a,
  info-light: #e7fbf8,
  mid-error: #ff2c2c,
  coming-soon: #ea4c70,
  pink: #ed1747,
  mid-blue: #0057e4,
  mid-green: #13804b,
  light: #fff,
  xlight-gray: #f5f6f8,
  light-gray: #dce0e0,
  semi-gray: #8e8f92,
  dark-gray: #183462,
  gray: #4a4a4a,
  darker: #2d3443,
  midnight: #292b30,
  orange: #ff9100,
  green-valid: #4caf50,
  dark-blue: #0033b8,
  med-blue: #007cff,
  light-blue: #6fbefb,
  current-user: #d8e8fa,
  med-gray: #6780a9,
  footer-gray: #b7b7b7,

  /// PRODUCT COLORS ///
  easyproperties: #17a4e1,
  mrphy: #0ddaba,
  easycredit: #03a96d,
  easyprotect: #8975c1,

  ep-easyequities: #ed1747,
  ep-easyproperties: #00588a,
  ep-easycrypto: #5308b2,
  ep-mrphy: #03a96d
);

/* Add font  face*/
@font-face {
  font-family: 'Gilroy Regular';
  src: url('../../assets/fonts/Gilroy-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy Semibold';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Gilroy-Semibold.woff') format('woff');
}
////////////////////////////////////////////////////////////

:root {
  &.theme--gcash-red {
    $assetsUrl: '../../assets/theme/easyid';
    @include setup-theme-icons($assetsUrl);

    @each $key, $color in $colors {
      --eid-col-#{""+$key}: #{$color};
      --eid-col-#{""+$key}-hover: #{darken($color, 5%)};
      --eid-col-#{""+$key}-active: #{darken($color, 10%)};
      --eid-col-#{""+$key}-transparent: #{rgba($color, 0)};
      @for $i from 1 through 9 {
        --eid-col-#{""+$key}-transparent-#{""+$i}: #{rgba($color, math.div($i, 10))};
      }
      --eid-col-#{""+$key}-focus: #{rgba($color, 0.3)};

      @if $key == 'accent' {
        --eid-col-#{""+$key}-contrast: #fff;
      } @else {
        --eid-col-#{""+$key}-contrast: #{choose-contrast-color($color)};
      }
    }

    --eid-value-input-color: var(--eid-col-mid-gray);
    --svg-icon-accent-color: var(--eid-col-accent-transparent-3);
    --svg-icon-accent-color-opacity: 1;

    --eid-base-font: 'Gilroy-Regular', sans-serif;
    --eid-accent-font: 'Gotham Rounded SSm', sans-serif;
    --eid-error-font: 'Poppins', sans-serif;
    --eid-error-base-font: 'Karla', sans-serif;
    --eid-minors-font: 'Montserrat', sans-serif;
    --eid-semi-font: 'Gilroy-SemiBold', sans-serif;
    --eid-game-base-font: 'Gotham Rounded SSm', sans-serif;
    --eid-game-accent-font: 'Questrial', sans-serif;

    --eid-base-font-weight: 400;
    --eid-med-font-weight: 500;
    --eid-dark-font-weight: 700;

    --comp-maintenance-image: url('../../assets/images/image-9-2x.jpg');
    --comp-maintenance-color: #ffcd55;

    --comp-error-image: url('../../assets/images/houston.jpg');
    --comp-error-color: #a2a7ad;

    --comp-header-app-logo: url('../../assets/theme/easyid/easyid-logo-colour.svg');
    --comp-header-app-logo-width: 95px;
    --comp-header-app-logo-height: 25px;

    --comp-footer-app-logo: url('../../assets/theme/easyid/easyid-logo-colour.svg');
    --comp-footer-app-logo-width: 95px;
    --comp-footer-app-logo-height: 25px;

    --comp-mobile-header-app-logo: url('../../assets/theme/easyid/easyid-logo-colour.svg');
    --comp-mobile-header-app-logo-width: 80px;
    --comp-mobile-header-app-logo-height: 23px;

    --comp-header-app-poweredlogo: url('../../assets/logos/poweredByEE.png');
    --comp-footer-app-poweredlogo: url('../../assets/logos/poweredByEE-colour.png');
    --comp-mobile-app-poweredlogo: url('../../assets/logos/poweredByEE-colour-gray-text.png');

    --eid-create-id-hero-bg: url('../../assets/theme/easyid/easyid-create-id.png');
    --eid-login-hero-bg: url('../../assets/theme/easyid/easyid-create-id.png');
    --eid-hero-bg: url('../../assets/theme/easyid/easyid-create-id.png');
    --eid-home-bg: url('../../assets/images/page-bg.png');
    --eid-why-bg: url('../../assets/images/why-easyid.svg');
    --eid-minor-account-banner: url('../../assets/theme/easyid/easyid-minor-account-banner.png');
    --eid-minor-account-banner-mobile: url('../../assets/theme/easyid/easyid-minor-account-banner.png');
    --eid-profile-individual: url('../../assets/theme/easyid/easyid-individuals.png');
    --eid-profile-kids: url('../../assets/theme/easyid/easyid-kids.png');
    --eid-profile-company: url('../../assets/theme/easyid/easyid-company.jpg');

    --eid-base-text-col: var(--eid-col-gray);
    --eid-value-text-col: var(--eid-col-mid-gray);
    --eid-base-text-col-mobile: var(--eid-col-mid-gray);
    --eid-border-color: var(--eid-col-light-gray);
    --eid-border-radius: 2px;
    --eid-max-border-radius: 8px;
    --eid-button-border-radius: 24px;
    --eid-form-border-radius: 24px;
    --eid-box-shadow: 0 4px 12px 0 rgba(70, 74, 82, 0.08);
    --eid-background-color: rgba(13, 218, 186, 0.07);
    --panel-horizontal-padding-lrg: 3.125rem;

    --comp-panel-bg: var(--eid-col-light);

    --comp-button-height: 50px;
    --comp-select-height: 40px;
    --comp-input-height: 40px;

    --comp-header-height: 80px;
    --comp-header-bg: var(--eid-col-gray);
    --comp-header-bg-light: var(--eid-col-light);
    --comp-header-col: var(--eid-col-light);
    --comp-header-border: none;
    --comp-header-ink-col: var(--eid-col-gray);
    --comp-header-app-poweredlogo-width: 120px;
    --comp-header-app-poweredlogo-height: 30px;

    --comp-themed-header-height: 80px;
    --comp-themed-header-background: var(--eid-col-light);
    --comp-themed-header-logo-width: 186px;
    --comp-themed-header-logo-height: 28px;

    --comp-footer-col: var(--eid-base-text-col);
    --comp-footer-top-height: 66px;
    --comp-footer-bottom-height: 42px;
    --comp-footer-top-border-col: var(--eid-col-light-gray);
    --comp-footer-top-col: var(--eid-col-mid-gray);
    --comp-footer-app-poweredlogo-width: 120px;
    --comp-footer-app-poweredlogo-height: 30px;
    --comp-footer-country-flag-height: 20px;

    --comp-stepper-color: var(--eid-col-accent);
    --comp-stepper-color-transparent: var(--eid-col-accent-transparent);
    --comp-stepper-color-focus: var(--eid-col-accent-focus);

    --comp-coming-soon-bg--kids: #f9b02a;
    --comp-coming-soon-fg--kids: #ffffff;
    --comp-coming-soon-bg--company: #89d8bc;
    --comp-coming-soon-fg--company: #ffffff;
  }

  /* --- FONT FAMILY & SIZES --- */
  h4 {
    font-size: 0.75rem;
    font-family: var(--eid-accent-font);
  }
}
