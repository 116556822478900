.mat-drawer-container {
  height: 100vh;
  background: var(--eid-col-xlight-gray);
  .mat-drawer {
    top: 59px;
    box-shadow: none !important;
  }
  .mat-drawer-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    .eid-header-mobile--private {
      flex: 0 0 60px;
      max-height: 60px;
    }

    .root-page {
      flex: 1 1 auto;
    }
  }
}
