.eid-action-tiles {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  margin: 1.75rem 0 3.25rem 0;

  @media screen and (min-width: 480px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    align-content: flex-start;
    flex-wrap: wrap;
  }

  .tile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    flex: 0 0 calc((100% - (1.5rem * 4)) / 5);
    min-width: 13.063rem;

    margin-top: 1.5rem;
    padding: 1.75rem;
    border-bottom: 4px solid var(--eid-col-accent-transparent);
    border-radius: var(--eid-max-border-radius);

    background: var(--eid-col-light);
    box-shadow: var(--eid-box-shadow);

    @include animate();
    cursor: pointer;
    text-decoration: none !important;

    &:first-child {
      margin-top: 0;
      @media screen and (min-width: 480px) {
        margin-top: 1.5rem;
      }
    }

    @media screen and (min-width: 480px) {
      flex-direction: column;
    }

    .mat-icon {
      flex: 0 0 2.25rem;
      width: 2.25rem;
      min-width: 2.25rem;
      height: 2.25rem;
      font-size: 2.25rem;
      line-height: 2.25rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      padding-left: 1.5rem;

      @media screen and (min-width: 480px) {
        align-items: center;

        padding-left: 0;
      }
    }

    &__title {
      margin: 0.875rem 0 0.5rem 0;

      font-family: var(--eid-accent-font);
      font-size: 1.25rem;
      color: var(--eid-col-gray);
    }
    &__description {
      font-size: 0.875rem;
      line-height: 1.4;
      text-align: left;
      color: var(--eid-col-mid-gray);

      @media screen and (min-width: 480px) {
        text-align: center;
      }
    }

    &--profile {
      .mat-icon {
        color: var(--eid-col-accent);
      }
    }
    &--manage {
    }
    &--funds {
      .mat-icon {
        color: #f4942a;
      }
    }
    &--security {
      .mat-icon {
        color: #0ddaba;
      }
    }
    &--support {
      .mat-icon {
        color: var(--eid-col-gray);
      }
    }

    &:hover {
      border-bottom-color: var(--eid-col-accent);
    }
  }
}
