.eid-current-user {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  height: calc(3rem - 1px);
  margin: 0;
  padding: 0 1.5rem;
  border: none;

  background: none;
  outline: none !important;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    height: 59px;
    padding: 0 1.5rem 0 0;
  }

  .mat-icon {
    color: var(--eid-col-mid-gray) !important;
  }
}

.eid-current-user-panel {
  max-width: 100vw !important;
  min-width: 100vw !important;
  width: 100vw !important;
  height: calc(100vh - 60px);
  border-radius: 8px !important;
  top: 10px;
  position: absolute !important;
  left: -12.5rem;

  @media screen and (min-width: 480px) {
    max-width: none !important;
    min-width: 288px !important;
    width: auto !important;
    height: auto !important;
  }

  .mat-menu-content {
    padding: 0 !important;
  }

  .account-details {
    .eid-linked-account {
      margin-top: 1.5rem;
    }
    .actions {
      margin: 1.5rem 0;
      .mat-menu-item {
        //padding-left: 1.5rem;
        font-size: 0.875rem;
        font-family: var(--eid-base-font);
        align-items: center;
        justify-content: center;
        display: flex;
        .mat-icon {
          width: 2rem !important;
          text-align: center;
          color: var(--eid-col-accent);
        }
      }
    }
  }

  .account-function {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 3.25rem;

    border-top: 1px solid var(--eid-border-color);

    font-family: var(--eid-base-font);
    font-size: 0.875rem;
    font-weight: 400;
  }
}
