.form-group {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  width: 100%;
  margin-bottom: 1.875rem;

  &--bottom-padding {
    padding-bottom: 0.5rem;
  }

  &__label {
    min-height: 1.5rem;
    line-height: calc(26px - 0.5rem);
    margin-bottom: 0.125rem;
    font-size: 0.875rem;
    color: var(--eid-col-gray);

    strong {
      font-weight: 600;
    }

    small {
      font-size: 0.75rem;
      font-weight: 400;
    }
  }

  &__info {
    font-size: 0.875rem;
    line-height: calc(26px - 0.5rem);
    color: var(--eid-col-mid-gray);
  }

  &--disabled {
    opacity: 0.75;
    pointer-events: none;
  }

  &__control {
    height: var(--comp-input-height);
    padding: 0 1rem;
    border: 1px solid var(--eid-border-color);
    border-radius: var(--eid-max-border-radius);

    outline: none;

    font-family: var(--eid-base-font);
    font-size: 0.875rem;

    border-radius: var(--eid-max-border-radius);

    &::selection {
      -webkit-text-fill-color: var(--eid-col-light);
    }

    &[type='number'] {
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    &[disabled] {
      background: var(--eid-col-xlight-gray);
      &:hover {
        border: 1px solid var(--eid-border-color);
      }
    }
    &:hover {
      border: 1px solid var(--eid-col-gray);
    }

    &:focus {
      border: 1px solid var(--eid-col-gray);
      box-shadow: none;
    }
  }

  &__control-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border: 1px solid var(--eid-border-color);
    border-radius: var(--eid-max-border-radius);

    .prefix {
      display: flex;
      justify-content: center;
      align-items: center;

      height: var(--comp-input-height);
      padding-left: 0.75rem;
      padding-right: 0.75rem;

      border-right: 1px solid var(--eid-border-color);

      font-size: 0.875rem;
      font-family: var(--eid-base-font);
      color: var(--eid-base-text-col);

      &--mobile-number {
        border-right: none;
        padding-right: 0.375rem;

        & + .form-group__control {
          padding-left: 0.375rem;
        }
      }
    }

    .form-group__control {
      flex: 1 1 auto;
      border: none;
      min-width: 0;
    }
    .mat-icon-button {
      --button-size: calc(var(--comp-input-height));
      width: var(--button-size);
      height: var(--button-size);
      border-radius: 0;
      border-left: 1px solid var(--eid-border-color);
      .mat-icon {
        color: var(--eid-col-mid-gray);
      }
    }
    &--disabled-fields {
      .form-group__control[disabled]:hover {
        border: none;
      }
      .prefix--mobile-number {
        background: var(--eid-col-xlight-gray);
        .form-group__control {
          border-radius: 0;
        }
      }
      .form-group__control {
        border-radius: 0;
      }
      .mat-icon-button {
        background: var(--eid-col-xlight-gray);
      }
    }
    &--number {
      max-height: 40px;
      border-radius: var(--eid-max-border-radius);
      &:hover {
        border: 1px solid var(--eid-col-gray);
      }
      .form-group {
        &__control {
          max-height: 38px;
        }
      }
    }
    &--date {
      .mat-icon-button {
        &:disabled {
          &:hover {
            border: none;
          }
        }
      }
      &:hover {
        border: 1px solid var(--eid-col-gray);
      }
      .form-group {
        &__control {
          &:disabled {
            &:hover {
              border: none;
            }
          }
        }
      }
    }
  }
  .disabled {
    &:hover {
      border: 1px solid var(--eid-border-color);
    }
    .mat-icon-button {
      background: var(--eid-col-xlight-gray);
      cursor: default;

      border-left-color: var(--eid-border-color);
      border-style: solid;
      border-width: 1px;
      border-right: none;
      border-top: none;
      border-bottom: none;
      .mat-ripple {
        display: none;
      }
    }
  }
  &__hint {
    margin-top: 0.5rem;

    font-size: 0.75rem;
    color: var(--eid-col-mid-gray);
  }

  &--link {
    font-size: 0.875rem;
    &--right {
      text-align: right;
    }
  }
}

.eid-input-control-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  background: var(--eid-col-light);

  &--transparent {
    background: none;
  }

  &--spacing-top {
    margin-top: 1rem;
  }
  &--spacing-bottom {
    margin-bottom: 1.5rem;
  }

  &__title {
    font-size: 0.75rem;
    font-weight: 600;

    margin: 1.5rem 1rem 0.65rem 1rem;
  }

  &__checkbox {
    margin: 1.5rem 1rem 0.65rem 1rem;
  }

  .mat-form-field.eid-text-input:last-child {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        border-bottom: none;
      }
    }
  }

  .mat-form-field.date-field.mat-form-field-should-float {
    .mat-form-field-infix {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .mat-input-element {
        order: 2;
        width: 130px;
        padding-right: 1rem !important;
        text-align: right;
      }
      .mat-form-field-label-wrapper {
        position: static;
        flex: 1 1 auto;
        order: 1;

        .mat-form-field-label {
          position: static;
          transform: none;
          width: auto;
        }
      }
    }
  }

  .divider {
    width: auto;
    height: 1px;
    margin: 0 1rem 0 1rem;

    background: var(--eid-border-color);
  }
  &--marketing-refferal {
    margin-top: 20px;
  }
}

.mat-form-field.eid-text-input {
  background: var(--eid-col-light);

  .mat-form-field-wrapper {
    padding: 0 1rem;
    .mat-form-field-flex {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0;

      .mat-form-field-prefix {
        width: 24px;
        height: 24px;
        margin-right: 0.5rem;

        .mat-icon {
          width: 20px;
          height: 20px;
          font-size: 20px;
          color: var(--eid-col-mid-gray);
          //color: var(--eid-col-gray);
        }
      }
      .mat-form-field-infix {
        padding: 0;
        border: none;

        .mat-input-element {
          margin: 0;
          padding: 0 0 0 0;
          height: 50px;
          line-height: 50px;

          color: var(--eid-base-text-col);
          -webkit-text-fill-color: var(--eid-col-mid-gray) !important;
          opacity: 1 !important;
          @media (min-width: 280px) and (max-width: 319px) {
            font-size: 0.875rem;
          }
        }
        .mat-form-field-label-wrapper {
          padding: 0;
          top: auto;
          .mat-form-field-label {
            height: 50px;
            line-height: 50px;
            top: 0;
            font-family: var(--eid-base-font);
            color: var(--eid-col-gray);

            @media (min-width: 280px) and (max-width: 319px) {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-subscript-wrapper {
    position: static;
    padding-left: 0;
    margin-top: 0;
    .mat-error {
      font-size: 0.875rem;
      color: var(--eid-col-error);
      padding-bottom: 0.5rem;
      border-bottom: 1px solid var(--eid-border-color);
    }
  }

  &.mat-focused {
    .mat-form-field-label {
      color: var(--eid-col-accent) !important;
    }
  }
  &.mat-form-field-invalid {
    .mat-form-field-label {
      color: var(--eid-base-text-col) !important;
    }
    .mat-input-element {
      color: var(--eid-base-text-col) !important;
      -webkit-text-fill-color: var(--eid-col-mid-gray) !important;
    }
  }
  &.mat-form-field-disabled {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-input-element {
            // color: var(--eid-col-mid-gray-transparent-6);
            // -webkit-text-fill-color: var(--eid-col-mid-gray-transparent-6) !important;
            color: var(--eid-col-mid-gray);
            -webkit-text-fill-color: var(--eid-col-mid-gray) !important;
            opacity: 1 !important;
          }
          .mat-form-field-label {
            color: var(--eid-col-mid-gray);
            -webkit-text-fill-color: var(--eid-col-mid-gray) !important;
            opacity: 1 !important;
          }
        }
      }
    }
  }
}
