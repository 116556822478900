.dialog {
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .btn-solid,
      .btn-outline {
        margin-left: 1rem;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
