.container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;

  margin: 0 auto;
  padding: 0 1rem;

  &--no-padding {
    padding: 0;
  }

  @media screen and (min-width: 576px) {
    max-width: 540px;
    width: 540px;
  }
  @media screen and (min-width: 600px) {
    max-width: 600px;
    width: 600px;
  }
  @media screen and (min-width: 768px) {
    max-width: 720px;
    width: 720px;
  }
  @media screen and (min-width: 992px) {
    max-width: 960px;
    width: 960px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 1140px;
    width: 1140px;
  }

  &-fluid {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;

    margin: 0 auto;
    padding: 0 1rem;

    &--pv-7 {
      padding-inline: 7.5rem;
    }
    &--pv-9 {
      padding-inline: 9.5rem;
    }
    &--pv-10 {
      padding-inline: 10rem;
    }
    &--pv-13 {
      padding-inline: 13rem;
    }
    &--pv-18 {
      padding-inline: 18rem;
    }
    &--pv-20 {
      padding-inline: 20rem;
    }

    @media screen and (max-width: 1200px) {
      &--pv-20 {
        padding-inline: 15rem;
      }
    }
    @media screen and (max-width: 994px) {
      &--pv-10 {
        padding-inline: 1rem;
      }
      &--pv-13 {
        padding-inline: 0;
      }
      &--pv-20 {
        padding-inline: 10rem;
      }
    }
    @media screen and (max-width: 767px) {
      &--pv-20 {
        padding-inline: 5rem !important;
      }
    }
  }
  &--home-page {
    max-width: 100% !important;
    width: 100% !important;
    padding: 0 !important;
  }
}
