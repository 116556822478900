.text {
  &--accent {
    color: var(--eid-col-accent) !important;
  }
  &--required {
    color: var(--eid-col-accent) !important;
    &__default {
      color: var(--eid-col-error);
    }
  }
  &--gray-light {
    color: var(--eid-col-mid-gray);
  }
}
