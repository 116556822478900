.mobile-page-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 1rem;

  button {
    &:only-child {
      width: 100%;
    }
  }
}
