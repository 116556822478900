.form {
  &__text-line {
    margin: 0 0 1rem 0;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .group {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      @include for-handset-only() {
        flex-wrap: wrap;
        width: 100%;
        gap: 1rem;

        .btn-solid,
        .btn-outline {
          flex: 1 1 auto;
          min-width: 100%;
        }
      }
    }

    &--center {
      justify-content: center;
      align-items: center;

      .group {
        justify-content: center;
        align-items: center;
      }
      p {
        margin: 1.5rem 0;
        font-size: 0.875rem;
        text-align: center;
      }
    }
  }
}
