.mat-menu-panel {
  .mat-menu-content {
    .mat-menu-item {
      text-decoration: none !important;
      height: auto;
      .nav-item {
        color: rgba(0, 0, 0, 0.87);
        text-decoration: none;
      }
    }
    .edit-action {
      color: var(--eid-col-mid-gray);
      font-family: var(--eid-base-font);
      font-size: 0.875rem;

      &:hover {
        background-color: #dce0e0;
        border-radius: 5px;
      }
    }
    &:not(:empty) {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  &.responsive-menu {
    border-radius: var(--eid-border-radius);

    .mat-menu-content {
      padding: 0;

      .mat-menu-item {
        text-decoration: none;

        font-family: var(--eid-base-font);

        &.active {
          background: var(--eid-col-accent);
          color: var(--eid-col-accent-contrast);
        }
        &:focus {
          box-shadow: none;
        }

        &:first-child {
          border-top-left-radius: var(--eid-border-radius);
          border-top-right-radius: var(--eid-border-radius);
        }
        &:last-child {
          border-bottom-left-radius: var(--eid-border-radius);
          border-bottom-right-radius: var(--eid-border-radius);
        }
      }
    }
  }
}
.eid-collapsed-menu {
  padding: 0.5rem 1rem 1rem 1rem;
  border-radius: 8px !important;
  max-width: 320px !important;
  left: 2%;
  transform: translateX(-50%);
  top: 1rem;
  position: absolute !important;
  //min-width: 320px !important;

  .mat-menu-content:not(:empty) {
    padding: 0;
  }
  &--heading {
    display: flex;
    align-items: center;
    h3 {
      margin-bottom: 0;
      margin-left: 0.5rem;
      font-family: var(--eid-base-font);
      color: var(--eid-col-gray);
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 20px;
    }
  }
  .menu-list {
    padding-top: 1.5rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    .item-container {
      width: 95px;
      padding-right: 5px;
      padding-bottom: 14px;
      .mat-menu-item {
        padding: 0;
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          .mat-icon {
            margin-right: 0;
            width: 30px;
            height: 30px;
          }
          span {
            font-family: var(--eid-base-font);
            font-size: 0.75rem;
            color: var(--eid-col-gray);
            font-weight: 400;
            line-height: 22px;
            text-align: center;
            font-style: normal;
            margin-top: 0.5rem;
          }
        }
        &:hover {
          background: none;
        }
      }
    }
  }
}
