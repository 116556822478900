.selector {
  --indicator-translate: -4px;
  --indicator-color: var(--eid-col-info);
  --divider-color: var(--eid-border-color);
  --selector-height: 50px;

  position: relative;
  display: block;
  width: 100%;

  .selector-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: var(--selector-height);

    background: var(--eid-col-light);
  }

  .selector {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    min-height: var(--selector-height);

    &--hasIcon {
      padding: 0.55rem 0;
    }
    &-prefix {
      display: flex;
      justify-content: center;
      align-items: center;

      flex: 0 0 var(--selector-height);
      height: var(--selector-height);

      .mat-icon {
        --size: 1.25rem;
        margin-inline: 1rem;
        width: var(--size);
        height: var(--size);
        font-size: var(--size);
        line-height: var(--size);
        color: var(--eid-col-mid-gray);
        @media (min-width: 280px) and (max-width: 319px) {
          margin-inline: 0.5rem 0px;
        }
      }

      &:empty {
        display: none;
      }
    }
    &-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      flex: 1 1 auto;
    }
    &-label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      flex: 1 1 auto;
      min-height: var(--selector-height);
      padding: 0.55rem 1rem;

      font-size: 1rem;
      font-weight: 400;
      text-align: start;

      @media (min-width: 280px) and (max-width: 319px) {
        font-size: 0.875rem;
      }

      .required {
        color: var(--eid-col-accent);
      }

      &--has-prefix {
        padding-inline-start: 0;
      }
    }
    &-value {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      flex: 1 1 auto;
      padding: 0.55rem 0 0.55rem 1rem;

      font-size: 1rem;
      font-weight: 400;
      text-align: flex-end;

      text-overflow: ellipsis;
      overflow: hidden;
      @media screen and (max-width: 320px) {
        padding: 0.4rem 0.4rem 0.4rem 0rem;
      }

      @media (min-width: 280px) and (max-width: 319px) {
        font-size: 0.875rem;
      }
    }
    &-action {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .mat-icon-button {
        color: var(--eid-col-mid-gray);
      }
    }
    &-validation {
      position: relative;
      z-index: 1;
      opacity: 0;
      transform: translateY(-1.5rem);
      font-size: 0.875rem;

      @include animate(transform);
      &:not(:empty) {
        transform: translateY(0);
        opacity: 1;
      }

      p {
        margin-inline: 1rem;
        margin-block: 0.55rem;
        color: var(--eid-col-accent);
        font-size: 0.875rem;
        text-align: start;

        &.hint {
          color: var(--eid-col-mid-gray);
        }
      }
    }
  }

  &.ng-touched {
    //&.ng-dirty {
    --indicator-translate: 0;
  }
  &.ng-invalid.ng-touched {
    --text-color: var(--eid-col-accent);
    --indicator-color: var(--eid-col-accent);
    --divider-color: var(--eid-col-accent);
  }
  &.ng-touched {
    //&.ng-dirty {
    &.ng-valid {
      --indicator-color: var(--eid-col-info);
    }
  }

  &.disabled,
  &.readonly {
    // .selector-value {
    //   color: var(--eid-col-mid-gray);
    //   -webkit-text-fill-color: var(--eid-col-mid-gray);
    // }
    .selector-action {
      display: none;
    }
  }
  &__dpip {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    padding-left: 1rem;
    background: var(--eid-col-light);
  }
  &--locked {
    .selector {
      &-value {
        padding: 0.55rem 3.4rem 0.55rem 1rem;
        @media screen and (max-width: 320px) {
          padding: 0.4rem 3rem 0.4rem 0rem;
        }
      }
    }
  }
}
.disabled {
  .selector {
    &-value {
      padding: 0.55rem 2.96rem 0.55rem 1rem;
    }
  }
}
