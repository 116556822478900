.mat-checkbox {
  .mat-checkbox-layout {
    .mat-checkbox-inner-container {
      height: 18px;
      width: 18px;
      .mat-checkbox-frame {
        border-width: 1px;
        border-color: var(--eid-border-color);
        border-radius: 4px;
      }
      .mat-checkbox-background {
      }
    }
    .mat-checkbox-label {
      font-size: 0.875rem;
      font-family: var(--eid-base-font);
      color: var(--eid-col-mid-gray);
      line-height: 1.4;
    }
  }

  &-checked {
    .mat-checkbox-background {
      background-color: var(--eid-col-accent) !important;
      border-radius: 4px;
    }
  }
}
