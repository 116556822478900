.validation-error {
  border-left: 4px solid var(--eid-col-error) !important;
}

.validation-success {
  border-left: 4px solid var(--eid-col-info) !important;
}

.validation-error-radio {
  border: 1px solid var(--eid-col-error);
}

.validation-error-mobile:after {
  content: '';
  position: absolute;
  left: 0px;
  top: 15%;
  bottom: 15%;
  border-left: 4px solid var(--eid-col-error) !important;
}

.validation-success-mobile:after {
  content: '';
  position: absolute;
  left: 0px;
  top: 15%;
  bottom: 15%;
  border-left: 4px solid var(--eid-col-info) !important;
}

.validation-message {
  margin-top: 0.45rem !important;
  margin-bottom: 0.55rem;
  margin-block-start: 0.75rem;
  padding: 0rem 1rem 0.1rem 1rem;

  font-size: 0.875rem;
  color: var(--eid-col-error);

  &.info {
    color: var(--eid-col-info);
  }

  &.warning {
    color: var(--eid-col-warning);
  }

  &.concent {
    padding: 0rem 1rem 0.1rem 0rem;
  }

  @include for-handset-up {
    padding: 0;
  }
}

.validation-message-mobile {
  border-top: 1px solid var(--eid-col-error) !important;
  margin-top: 0 !important;
  margin-bottom: 0.55rem;
  margin-block-start: 0.75rem;
  padding: 0.5rem 1rem 0.1rem 1rem;

  font-size: 0.875rem;
  color: var(--eid-col-error);

  &.info {
    border-top: 1px solid var(--eid-col-info) !important;
    color: var(--eid-col-info);
  }

  &.warning {
    color: var(--eid-col-warning);
  }

  @include for-handset-up {
    padding: 0;
  }
}

.validation-summary {
  margin-block: 2rem;
  padding: 0 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4;
  text-align: start;
  color: var(--eid-col-error);

  p {
    margin: 0 0 1rem 0;
    text-align: start !important;
  }

  ul {
    margin-inline-start: 1rem;
    list-style-type: disc;

    li {
      margin-block-start: 0.55rem;

      &:first-child {
        margin-block-start: 0;
      }
    }
  }

  @include for-handset-up() {
    width: 100%;
    padding: 1rem 1.5rem 1.5rem;
    border: 1px solid var(--eid-col-error);
    &__heading {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      font-size: 1.125rem;
      line-height: 1.2;
      color: var(--eid-col-gray);

      .mat-icon {
        overflow: visible;
        margin-inline-end: 1rem;
        color: var(--eid-col-error);
      }
    }
    ul {
      margin-block-start: 1.5rem;
    }
  }
}
