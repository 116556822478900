.ff-accent {
  font-family: var(--eid-accent-font);
}
.fw-600 {
  font-weight: 600 !important;
}

.fs {
  &-12 {
    font-size: 0.75rem;
  }
  &-14 {
    font-size: 0.875rem;
  }
  &-18 {
    font-size: 1.125rem;
  }
}

.fc {
  &-mid-gray {
    color: var(--eid-col-mid-gray);
  }
  &-gray {
    color: var(--eid-col-gray);
  }
  &-accent {
    color: var(--eid-col-accent);
  }
}

.lh {
  &-tight {
    line-height: 1.2;
  }
  &-loose {
    line-height: 1.5;
  }
}
