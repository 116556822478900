eid-contracting-entity {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-size: 0.75rem;
  font-weight: 600;
  color: var(--eid-base-text-col);

  .flag {
    --flag-width: calc(var(--comp-footer-country-flag-height) * var(--comp-footer-country-flag-ratio));
    flex: 0 0 var(--flag-width);
    min-width: var(--flag-width);
    max-width: var(--flag-width);
    height: var(--comp-footer-country-flag-height);

    margin-right: 10px;

    background: var(--comp-footer-country-flag);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;
  }
}
