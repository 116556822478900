.eid-navigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  a:not(.btn-solid) {
    display: none;

    padding: 0.5rem 0.1rem;
    margin: 0 0.75rem;
    //border-bottom: 2px solid var(--eid-col-gray);
    border-bottom: none;

    font-family: 'Open Sans';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-decoration: none;

    color: var(--eid-col-mid-gray);

    cursor: pointer;

    &:focus {
      outline: none;
      box-shadow: none !important;
    }

    &.active {
      border-bottom: 2px solid var(--comp-header-ink-col);
    }

    @media screen and (min-width: 775px) {
      display: block;
    }
  }

  .collapsed-menu {
    display: inline-block;

    @media screen and (min-width: 775px) {
      display: none;
    }
  }
}
