.mat-tooltip {
  position: relative;
  margin: 0;
  padding: 0.75rem 1rem !important;
  border: 1px solid var(--eid-border-color);

  background: var(--eid-col-light);
  box-shadow: var(--eid-box-shadow);
  border-radius: 0 !important;
  color: var(--eid-base-text-col) !important;

  font-size: 0.875rem;
  line-height: 1.5;

  overflow: visible !important;

  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 1rem;
    height: 0.5rem;

    background: var(--eid-border-color);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
  &::after {
    content: '';
    z-index: 2;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(2px);
    display: block;
    width: 1rem;
    height: 0.5rem;

    background: var(--eid-col-light);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
  &.multiline {
    white-space: pre-line;
    max-width: unset !important;
  }
  &.question-tip {
    width: 458px;
    height: 254px;
    border-radius: var(--eid-max-border-radius) !important;
    transform-origin: center bottom;

    &__mobile {
      width: 340px;
      height: 311px;
      position: absolute;
      right: 103px;
      top: 30px;

      @media (max-width: 414px) {
        right: -59px;
        top: -370px;
      }
      @media (max-width: 412px) {
        right: 8px;
      }
      @media (min-width: 375px) {
        right: -60px;
        top: -370px;
      }
      @media (max-width: 360px) {
        right: -60px;
        top: -370px;
      }
      @media (max-width: 320px) {
        width: 300px;
        height: 360px;
        right: -40px;
        top: -430px;
      }
      &::before {
        left: 90%;
        @media (max-width: 320px) {
          left: 95%;
        }
      }
      &::after {
        left: 90%;
        @media (max-width: 320px) {
          left: 95%;
        }
      }
    }
    &::before {
      clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
      bottom: -9px;
    }
    &::after {
      clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
      bottom: -6px;
    }
  }
  &.copy-tooltip {
    width: 226px;
    border-radius: 8px !important;
    font-family: var(--eid-accent-font);
    font-size: 0.75rem;
    line-height: 18px;
    &::before {
      clip-path: polygon(50% 100%, 0 0, 100% 0);
      bottom: -5%;
      left: 85%;
    }
    &::after {
      clip-path: polygon(50% 100%, 0 0, 100% 0);
      bottom: -3%;
      left: 85%;
    }

    &--manage {
      &::before {
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        bottom: 100%;
        left: 90%;
      }
      &::after {
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        bottom: 100%;
        left: 90%;
      }
    }
  }
}
