.mat-form-field {
  &--mobile {
    font-family: var(--eid-base-font);
    .mat-form-field-wrapper {
      padding-bottom: 0;
      .mat-form-field-flex {
        align-items: center;

        padding-left: 1rem;
        padding-right: 1rem;

        .mat-form-field-prefix,
        .mat-form-field-suffix {
          .mat-icon {
            width: 20px;
            height: 20px;
            font-size: 20px;
            //color: var(--eid-col-gray);
            -webkit-text-fill-color: var(--eid-col-mid-gray);
            color: var(--eid-col-mid-gray);
          }
          .disabled-fields {
            display: none;
          }
        }
        .mat-form-field-prefix {
          margin-right: 1rem;
          @media (min-width: 280px) and (max-width: 319px) {
            margin-right: 0.5rem;
          }
        }
        .mat-form-field-suffix {
          margin-left: 1rem;
        }

        .mat-form-field-infix {
          padding: 0;
          border: none;
          height: 50px;

          display: flex;
          align-items: center;
          @media (min-width: 280px) and (max-width: 319px) {
            font-size: 0.875rem;
          }

          .custom-field {
            width: 50%;
            &--label {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 62%;
              .custom-marker {
                color: var(--eid-col-accent);
              }
              @media screen and (min-width: 375px) {
                width: 100%;
              }
              @media (min-width: 320px) and (max-width: 360px) {
                width: 70%;
              }
              &__address {
                width: 40%;
                @media (min-width: 320px) and (max-width: 360px) {
                  width: 33%;
                }
              }
              &__sm {
                width: 30%;
              }
              &__md {
                width: 73%;
                @media screen and (min-width: 375px) {
                  width: 90%;
                }
                @media (min-width: 320px) and (max-width: 360px) {
                  width: 36%;
                }
              }
              &__lrg {
                width: 75%;
              }
              &__ref {
                width: 100%;
              }
              &__email {
                width: calc((100% - 80px) / 2);
                @media screen and (min-width: 375px) {
                  width: calc((100% - 20px) / 2);
                }
                @media (min-width: 280px) and (max-width: 319px) {
                  width: 80%;
                }
              }
              .sub-text {
                font-size: 0.75rem;
                color: var(--eid-col-mid-gray);
              }
            }
            &--input {
              .mat-input-element {
                width: 100%;
                margin-right: 5px;
                padding-left: 15px;
                @media (min-width: 280px) and (max-width: 319px) {
                  width: 90%;
                }
              }
              @media (min-width: 320px) and (max-width: 360px) {
                width: 28%;
              }
              &__md {
                @media (min-width: 375px) and (max-width: 414px) {
                  width: 65%;
                }
              }
              &__lrg {
                width: 100%;
                @media (min-width: 320px) and (max-width: 360px) {
                  width: 70%;
                }
              }
              &__address {
                width: 60%;
                @media (min-width: 320px) and (max-width: 360px) {
                  width: 67%;
                }
              }
              &__email {
                width: calc((100% + 135px) / 2);
              }
            }
            &--textarea {
              flex-direction: column;
              align-items: baseline;
              width: 100%;
              .mat-input-element {
                text-align: left;
                height: 40px;
              }
            }
          }

          .mat-input-element {
            height: 50px;
            padding-left: 0;
            font-family: var(--eid-base-font);
            font-size: 16px;
            position: relative;
            left: 0px;
            width: 100%;
            float: right;
            top: 0px;
            text-overflow: ellipsis;
            text-align: right;
            margin-right: 22px;
          }
          .id-number {
            @media (min-width: 280px) and (max-width: 319px) {
              left: 130px;
              width: 42%;
              font-size: 0.875rem;
            }
          }
          .mat-form-field-label-wrapper {
            padding: 0;
            top: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            line-height: 50px;
            padding-left: 0;
            .mat-form-field-label {
              position: static;
              top: auto;
              transform: none;
              color: var(--eid-col-mid-gray);

              mat-label {
                color: var(--eid-base-text-col-mobile);
              }
              .mat-placeholder-required {
                //display: inline-block;
                display: none;
                color: var(--eid-col-accent);
              }
            }
          }
        }
      }
      .mat-form-field-underline {
        bottom: 0;
        background: none;
        .mat-form-field-ripple {
          display: none;
        }
      }
      .mat-form-field-subscript-wrapper {
        top: auto;
        position: static;

        margin: 0;
        padding-bottom: 0.25rem;
        padding-left: 1rem;
        padding-right: 1rem;

        .mat-error {
          font-size: 0.875rem;
          padding-bottom: 0.25rem;
          color: var(--eid-col-error);
        }
      }
    }

    &.mat-form-field-invalid {
      .mat-form-field-wrapper {
        .mat-form-field-infix {
          .mat-form-field-label-wrapper {
            .mat-form-field-label {
              color: var(--eid-col-error);
            }
          }
        }
      }
    }
    &.mat-focused {
      .mat-form-field-wrapper {
        .mat-form-field-infix {
          .mat-form-field-label-wrapper {
            .mat-form-field-label {
              color: var(--eid-col-mid-gray);
            }
          }
        }
      }
    }

    & + .validation {
      margin: 0;
      padding-bottom: 0.25rem;
      padding-left: calc(2rem + 20px);

      font-size: 0.75rem;
      color: var(--eid-col-error);
      border-bottom: 1px solid var(--eid-col-error);
    }
    &__number {
      .mat-form-field-prefix {
        margin-right: 0 !important;
      }
      .custom-field {
        &--label {
          @media (min-width: 320px) and (max-width: 360px) {
            width: 50% !important;
          }
        }
        &--input {
          display: flex;
          width: 45%;
        }
      }
    }
    &__custom-label {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-infix {
            .mat-form-field-label-wrapper {
              .mat-form-field-label {
                mat-label {
                  color: var(--eid-col-gray);
                  .custom-marker {
                    color: var(--eid-col-accent);
                  }
                }
              }
            }
          }
        }
      }
    }

    &__textarea {
      .mat-input-element {
        top: 20px !important;
        left: 20px !important;
        text-align: left !important;
      }
    }
    &__password {
      .custom-field {
        &--label {
          &__md {
            @media (min-width: 320px) and (max-width: 360px) {
              width: 40% !important;
            }
          }
        }
        &--input {
          padding-right: 0 !important;
        }
      }
      .mat-form-field-infix {
        display: flex;
        justify-content: flex-end;
        .col-md {
          width: 100% !important;
          margin-right: 5px !important;

          @media (min-width: 320px) and (max-width: 360px) {
            width: 100% !important;
            padding-left: 5px !important;
          }
        }
      }
      .mat-form-field-suffix {
        @media screen and (max-width: 320px) {
          margin-left: 0.5rem !important;
        }
      }
    }
  }
  &--custom-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        padding-top: 1rem;
      }
    }
  }
  &--bg-color {
    background-color: var(--eid-col-light);
  }

  &--pep {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          height: fit-content;
          padding-top: 5px;
          padding-bottom: 5px;
          .mat-input-element {
            width: 0px;
          }
          .mat-form-field-label-wrapper {
            line-height: 20px;
          }
        }
      }
    }
  }
}

.mat-form-field-disabled {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        .custom-field {
          &--label {
            color: var(--eid-col-mid-gray);
          }
        }
      }
      .mat-form-field-prefix {
        .disabled-fields {
          display: block;
        }
      }
    }
  }
}

.mat-form-field--textarea-input {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: unset;
        width: 100%;
        flex-wrap: wrap;
        .mat-input-element {
          max-width: calc((100% + 330px) / 2);
          margin-right: 31px;
          margin-top: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .mat-form-field-label-wrapper {
          line-height: initial;
          height: calc((100% + 0px) / 2);
          margin-top: 3px;
          .mat-form-field-label {
            mat-label {
              color: var(--eid-col-gray);
            }
            .mat-placeholder-required {
              display: inline-block;
              color: var(--eid-col-accent);
            }
          }
        }
      }
    }
  }
}
.mat-form-field--lrg-input {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        display: flex;
        justify-content: flex-end;
        .mat-input-element {
          width: 50%;
        }
      }
    }
  }
}
