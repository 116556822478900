.page-title {
  width: 100%;
  padding: 1.5rem 0;

  text-align: center;
  color: var(--eid-base-text-col);

  h1 {
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: var(--eid-accent-font);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.6875rem;
    text-align: center;
    letter-spacing: -0.58px;

    @include for-handset-up() {
      padding-left: 0;
      padding-right: 0;
      font-size: 1.875rem;
      line-height: 2rem;
      letter-spacing: -0.72px;
    }
  }
  .page-subtitle {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 19px;
    color: var(--eid-col-mid-gray);
    @include for-handset-up() {
      padding-left: 0;
      padding-right: 0;
    }
    a {
      color: var(--eid-col-accent);
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    @include for-handset-up() {
      margin-top: 0.375rem;
      font-size: 1.125rem;
      line-height: 22px;
    }
  }
}
