.mat-form-field-type-mat-select {
  width: 100%;
  padding-bottom: 1.875rem;

  &--sml-padding {
    padding-bottom: 1rem;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
    .mat-form-field-flex {
      .mat-form-field-infix {
        display: flex;
        flex-direction: column;
        padding: 0;
        border: 0;
        .mat-select {
          order: 1;
          height: var(--comp-select-height);
          border: 1px solid var(--eid-border-color);
          border-radius: var(--eid-max-border-radius);
          margin-top: -0.375rem;
          &:focus {
            border: 1px solid var(--eid-col-gray);
            box-shadow: none;
          }
          &:active {
            border: 1px solid var(--eid-col-info);
            box-shadow: none;
          }
          .mat-select-trigger {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: calc(var(--comp-select-height) - 2px);
            .mat-select-value {
              display: block;
              flex: 1 1 auto;
              max-width: none;
              height: 1rem;
              padding-left: 1rem;

              font-family: var(--eid-base-font);
              font-size: 0.875rem;
              //color: var(--eid-base-text-col);
              .mat-select-placeholder {
                color: var(--eid-value-text-col);
                opacity: 0.6;
              }
            }
            .mat-select-arrow-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 0 0 calc(var(--comp-select-height) - 2px);
              height: calc(var(--comp-select-height) - 2px);

              border-left: 1px solid var(--eid-border-color);

              .mat-select-arrow {
                color: var(--eid-col-mid-gray);
              }
            }
            .mat-select-arrow-wrapper:hover {
              border-left: 1px solid var(--eid-col-gray);
            }
          }

          &.mat-select-disabled {
            background: var(--eid-col-xlight-gray);

            .mat-select-trigger {
              .mat-select-value {
                color: var(--eid-col-mid-gray);
              }
              .mat-select-arrow-wrapper:hover {
                border-left: 1px solid var(--eid-border-color);
              }
            }
            &:hover {
              border: 1px solid var(--eid-border-color);
            }
            &:focus {
              border: 1px solid var(--eid-border-color);
            }
          }
          &:hover {
            border: 1px solid var(--eid-col-gray);
          }
        }
        .validation-success {
          .mat-select-value {
            color: var(--eid-value-text-col);
          }
        }
        .mat-form-field-label-wrapper {
          order: 0;
          position: static;
          top: auto;
          padding: 0 0 0.5rem 0;
          min-height: 1.5rem;
          .mat-form-field-label {
            position: static;
            top: auto;
            transform: none;
            width: 100%;
            mat-label {
              white-space: normal;
              font-family: var(--eid-base-font);
              font-size: 0.875rem;
              color: var(--eid-base-text-col);
            }
          }
        }
      }
    }
    .mat-form-field-underline {
      background: none;
      .mat-form-field-ripple {
        display: none;
      }
    }
    .mat-form-field-subscript-wrapper {
      position: static;
      margin-top: 0.5rem;
      top: auto;
      font-size: 0.75rem;
      font-family: var(--eid-base-font);

      .mat-error {
        font-size: 0.875rem;
        padding-bottom: 0.25rem;
        color: var(--eid-col-error);
      }
    }
  }

  &.mat-focused {
    .mat-select {
      &:focus {
        border: 1px solid var(--eid-col-info);
        box-shadow: none;
      }
      @include focus();
    }
    .mat-select-arrow {
      color: var(--eid-col-mid-gray);
    }
  }

  &--no-label {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-form-field-label-wrapper {
            display: none;
          }
        }
      }
    }
  }
}

.mat-select-panel-wrap {
  .mat-select-panel {
    max-width: none;
    .mat-option {
      line-height: 48px;
      height: 48px;
      font-size: 0.875rem;
      font-family: var(--eid-base-font);
      color: var(--eid-base-text-col);
      &.mat-selected {
        color: var(--eid-col-accent);
        &:not(.mat-option-multiple) {
          background: var(--eid-col-xlight-gray);
        }
      }
    }
  }
}
