.maintenance {
  .topics {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }

    .card {
      flex: 1 1 auto;
      margin-left: 3rem;
      padding: 0;

      background-size: cover !important;
      background-color: var(--eid-col-light) !important;

      text-decoration: none;

      @media screen and (max-width: 767px) {
        width: 320px;
        max-width: 320px;
        margin: 0 0 2rem 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &::before {
        display: block;
        content: '';
        width: 100%;
        height: 0;
        padding-top: calc(100% - 80px);
      }

      .caption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        height: 80px;

        color: var(--eid-col-light);
        background: var(--eid-col-purple-transparent-7);

        h4 {
          margin: 0;
          font-size: 1.25rem;
          height: 30px;
        }
        p {
          margin: 0;
          font-size: 0.875rem;
        }
      }
    }
  }

  .knowledge-base {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    margin: 0 10rem;

    @media screen and (max-width: 991px) {
      margin: 0;
    }
    @media screen and (max-width: 575px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .image {
      position: relative;

      width: 155px;
      min-width: 155px;
      height: 155px;
      border-radius: 155px;

      .mat-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        width: 3rem;
        height: 3rem;

        background: var(--eid-col-accent);
        color: var(--eid-col-light);
        border-radius: 3rem;
      }

      @media screen and (max-width: 575px) {
        margin-bottom: 2rem;
      }
    }
    .content {
      margin-left: 2rem;
      color: var(--eid-col-mid-gray);

      h3 {
        font-size: 1.125rem;
        margin-bottom: 1rem;
      }
      ul {
        li {
          line-height: 1.5;
        }
      }
      .btn-outline {
        margin-top: 1rem;
      }
    }
  }

  .contact {
    .row {
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    .page-title {
      text-align: left;
      @media screen and (max-width: 575px) {
        h1 {
          padding-left: 0;
        }
      }

      h1 {
        text-align: left;
      }

      p:not(.page-subtitle) {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        line-height: 1.2;
      }
      @media screen and (max-width: 575px) {
        .error-btns {
          display: flex;
          flex-direction: column;
          align-items: center;
          .btn-solid {
            width: fit-content;
            &:nth-child(1) {
              margin-bottom: 1rem;
            }
          }
        }
      }
    }

    img {
      width: 100%;
      max-width: 600px;
      height: auto;
    }
  }
}
