.panel {
  --panel-horizontal-padding: 1.5rem;

  position: relative;

  width: 100%;
  padding: 1.5rem var(--panel-horizontal-padding) 1.875rem var(--panel-horizontal-padding);

  @media (min-width: 280px) and (max-width: 319px) {
    padding: 1.5rem 1rem 1.875rem 1rem;
  }
  @include panel();

  hr.full-span {
    margin-left: calc(-1 * var(--panel-horizontal-padding));
    margin-right: calc(-1 * var(--panel-horizontal-padding));
  }

  &--padded-half {
    --panel-horizontal-padding: 4rem;
  }
  &--padded {
    --panel-horizontal-padding: 2rem;

    @media screen and (min-width: 775px) {
      --panel-horizontal-padding: 6.25rem;
    }
  }

  &__title {
    margin-bottom: 1.875rem;
    h2 {
      font-family: var(--eid-accent-font);
      font-size: 1.625rem;
      font-weight: 400;
      line-height: 2.25rem;
      letter-spacing: 0.63px;
      color: var(--eid-col-gray);
    }
    h3 {
      margin-top: 0.5rem;
      font-family: var(--eid-accent-font);
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;
      letter-spacing: 0.48px;
      color: var(--eid-col-gray);
      @media (min-width: 320px) and (max-width: 768px) {
        font-size: 1.375rem;
      }
    }
    &__subtitle {
      margin-top: -2.5rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.375rem;
      color: var(--eid-col-mid-gray);

      a {
        color: var(--eid-col-accent);
        outline: none;

        &:focus {
          @include focus(var(--eid-col-accent));
        }
      }
      &--left {
        text-align: left;
      }
    }

    &--center {
      text-align: center;
    }
  }

  &--center {
  }

  &--login {
    max-width: 450px;
    margin-top: -350px;
    margin-left: auto;
    margin-right: auto;
  }
  &--recovery {
    max-width: 450px;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  &--landing-page {
    box-shadow: none;

    .panel {
      &__title {
        width: 70%;
        margin: 0 auto;
        @media (min-width: 320px) and (max-width: 768px) {
          width: 100%;
        }
        @media (min-width: 280px) and (max-width: 319px) {
          width: 100%;
        }
        h3 {
          font-size: 1.875rem;
          text-align: center;
          @media (min-width: 280px) and (max-width: 319px) {
            font-size: 1.5rem;
          }
        }
      }
      &__faq {
        width: 100%;
        padding: 0 15rem;
        @media (min-width: 280px) and (max-width: 319px) {
          padding: 0 0.875rem;
          .btn-outline {
            height: 85px;
            text-align: left;
          }
        }
      }
      &__why-sign-up {
        //width: 70%;
        width: 92%;
        @media (min-width: 414px) and (max-width: 768px) {
          width: 100%;
        }
        @media (min-width: 320px) and (max-width: 400px) {
          width: 96%;
        }
        @media (min-width: 280px) and (max-width: 319px) {
          width: 100%;
        }
      }
    }
  }
  &--mobile {
    border-radius: 8px;
    margin-bottom: 25px;
    padding: 0.75rem;
  }
}
