.eid-linked-account {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 1.5rem;
  .account-user {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-right: 1.5rem;

    eid-avatar {
      --eid-avatar-size: 2.375rem;
    }

    .name {
      font-size: 0.875rem;
    }
  }
  .eid-account-type {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 95px;
    height: 1.5rem;
    padding: 0.4em 1em;

    border-radius: 4px;
    font-size: 0.625rem;
    font-weight: 600;
    text-align: center;

    &--minor {
      background: rgba(#5308b2, 0.2);
    }
    &--linked {
      background: rgba(#0ddaba, 0.2);
    }
  }
}
