.table {
  width: 100%;

  thead {
    tr {
      th {
        padding: 0.25rem 0;
        font-size: 0.875rem;
        font-weight: 600;
        text-align: left;

        & + th {
          text-align: right;
        }
      }
      &:last-child {
        border-bottom: 1px solid currentColor;

        th {
          padding-bottom: 1rem;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 0.25rem 0;
        font-size: 0.875rem;
        color: var(--eid-col-mid-gray);
        & + td {
          text-align: right;
        }
      }

      &:first-child {
        td {
          padding-top: 1rem;
        }
      }
      &.verified-contributions {
        td {
          padding: 1rem 0;
          font-weight: 600;
          color: var(--eid-col-gray);
          border-top: 1px solid currentColor;
          border-bottom: 1px solid currentColor;
        }
      }
    }
  }
  tfoot {
    tr {
      td {
        padding: 1rem 0;
        font-size: 0.875rem;
        font-weight: 600;
        color: var(--eid-col-info);
        & + td {
          text-align: right;
        }
      }
    }
  }
}

.data-table {
  border: 1px solid var(--eid-border-color);

  thead {
    background: var(--eid-col-xlight-gray);
    tr {
      th {
        padding: 0.75rem 1.5rem;
        font-size: 0.75rem;
        font-weight: 600;
        text-align: left;
        text-transform: uppercase;
        color: var(--eid-base-text-col);
      }
    }
  }
  tbody {
    tr {
      &.border--top {
        &:not(:first-child) {
          border-top: 1px solid var(--eid-border-color);
        }
      }
      td {
        padding: 1rem 1.5rem;
        vertical-align: middle;

        font-size: 0.875rem;
        color: var(--eid-col-mid-gray);
      }
    }
  }

  &__column {
    &--account {
      font-weight: 600;
      color: var(--eid-base-text-col) !important;
    }
  }
}
