.form-hint {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.4;
  color: var(--eid-col-mid-gray);

  strong {
    font-weight: 600;
  }

  a {
    color: var(--eid-col-accent);
    outline: none;

    &:focus {
      @include focus(var(--eid-col-accent-focus));
    }
  }

  &--no-margin {
    margin-top: 0;
    margin-bottom: 0;
  }

  &--mobile {
    padding: 0 1rem;
    font-size: 0.75rem;

    img {
      display: inline-block;
      max-height: 1rem;
      width: auto;
    }
  }

  &--right {
    text-align: right;
  }
}
