.text-block {
  &--small {
    font-size: 0.6875rem;
    line-height: 1.125rem;

    a:not(.accent) {
      text-decoration: none;
      color: inherit;
    }
  }
  &--medium {
    margin-bottom: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &--light {
    color: var(--eid-col-mid-gray);
  }

  &--has-bg {
    padding: 1rem 1.5rem;

    &--gray {
      background: var(--eid-col-xlight-gray);
    }
  }

  &--spacing-bottom {
    margin-bottom: 2.5rem;
  }

  h4 {
    margin-bottom: 1rem;
  }
}

.text-block-mobile {
  padding: 1rem;

  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.1875rem;

  color: var(--eid-col-mid-gray);
}
