.cards-container--mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  @media (min-width: 280px) and (max-width: 319px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .card {
    position: relative;
    margin-bottom: 1rem;
    padding: 1rem 1.5rem 1.5rem 1.5rem;

    background: var(--eid-col-light);
    box-shadow: var(--eid-box-shadow);
    border: 1px solid var(--eid-col-light);

    @media (min-width: 280px) and (max-width: 319px) {
      padding: 1rem 1rem 1rem 1rem;
    }
    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      padding: 0;
      width: 68px;
      height: 68px;

      border-radius: 68px;
    }
    &__label {
      font-family: 0.875rem;
      font-weight: 600;
      letter-spacing: -0.34px;
      line-height: 1rem;
      text-align: center;
      margin-top: 1rem;
    }

    &__description {
      margin: 0.75rem 0 0 0;
    }

    .mat-icon.check {
      transform: scale(0);
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;

      color: var(--eid-col-accent);
    }

    &--active,
    &:active {
      border: 1px solid var(--eid-col-accent);
      .mat-icon.check {
        transform: scale(1);
      }
    }
  }
}
