.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  &--justify-space-between {
    justify-content: space-between;
  }
  &--justify-center {
    justify-content: center;
  }
  &--justify-end {
    justify-content: flex-end;
  }
  &--align-center {
    align-items: center;
  }
  &--align-bottom {
    align-items: flex-end;
  }

  &--align-left {
    text-align: left;
  }

  .col {
    flex: 1 1 auto;
  }

  &--col-2 {
    justify-content: space-between;
    .col {
      flex: 0 0 calc((100% - 1.5rem) / 2);

      &:only-child {
        flex: 0 0 100%;
      }
    }
  }
  &--min-height {
    min-height: 90px;
    .mat-auto-form-field {
      padding-bottom: 0.5rem;
    }
    .mat-form-field-type-mat-select {
      padding-bottom: 1rem;
    }
  }
}
