.dialog-mobile {
  .mat-dialog-container {
    padding: 0 !important;
    > * {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      overflow: hidden;
    }
    .mat-dialog-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      flex: 0 0 52px;
      height: 52px;
      margin: 0;

      background: var(--eid-col-xlight-gray);

      .mat-icon-button {
        flex: 0 0 40px;

        &:first-child {
          margin-left: 6px;
        }
        &:last-child {
          margin-right: 6px;
        }

        .mat-icon {
          color: var(--eid-col-gray);
          opacity: 0.5;
        }
      }
      span {
        flex: 1 1 auto;
        text-align: center;

        font-family: var(--eid-base-font);
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: -0.43px;

        color: var(--eid-col-gray);
      }
    }
    .mat-dialog-content {
      margin: 0;
      padding: 1.5rem;

      font-size: 1rem;
      font-family: var(eid-base-font);
      font-weight: 400;
      line-height: 1.5rem;
      color: var(--eid-col-mid-gray);

      text-align: center;
    }
    .mat-dialog-actions {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      height: calc(50px + 2rem);
      flex: 0 0 calc(50px + 2rem);

      margin: 0;
      padding: 1rem;

      button {
        margin-left: 0.5rem;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
