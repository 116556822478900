.mobile-page-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  width: 100%;
  height: 100%;

  .page-actions {
    flex: 0 0 calc(3.125rem + 2.5rem);
  }
}
