.user-details {
  border-bottom: 1px solid var(--eid-border-color);

  eid-avatar {
    margin: 1.5rem auto 1.5rem auto;
  }
  &__name {
    margin: 0.5rem 0 1rem 0;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 600;
  }
  &__details {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    margin-bottom: 1.5rem;

    .detail {
      border-left: 1px solid var(--eid-border-color);
      padding: 0.25rem 1.5rem;
      font-size: 0.75rem;

      &:first-child {
        border-left: 0;
      }

      .label {
        margin-bottom: 0.5rem;
        font-weight: 600;
        text-transform: uppercase;
      }
      .value {
        overflow-wrap: anywhere;
      }
    }
  }
  @include for-handset-only() {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    border-bottom: none;

    eid-avatar {
      --eid-avatar-size: 3.125rem;

      margin-left: 1rem;
      margin-right: 1rem;
    }

    .user-details__name {
      flex: 1 1 auto;
      text-align: left;
      font-weight: 400;
      width: 50%;

      .name {
        font-size: 1.125rem;
      }
      .date {
        font-size: 0.875rem;
        line-height: 1.5;
      }
    }
    .user-details__details {
      flex: 0 0 100%;

      .detail {
        .label {
          color: var(--eid-col-gray);
        }
        .value {
          font-size: 0.875rem;
          color: var(--eid-col-mid-gray);
        }
      }
    }
  }
}
