.eid-private-header-desktop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  height: auto;

  border-bottom: 1px solid var(--eid-border-color);
  background: var(--eid-col-light);
  &--minimal {
    height: 5rem;
    .logo {
      margin-left: 0;
    }
  }

  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 70px;
    margin-left: 10px;

    &__app {
      flex: 0 0 var(--comp-footer-app-logo-width);
      min-width: var(--comp-footer-app-logo-width);
      height: var(--comp-footer-app-logo-height);

      margin-right: 1.5rem;

      background-image: var(--comp-footer-app-logo);
      background-position: 0 50%;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &__powered-by {
      flex: 0 0 var(--comp-footer-app-poweredlogo-width);
      min-width: var(--comp-footer-app-poweredlogo-width);
      height: var(--comp-footer-app-poweredlogo-height);

      background-image: var(--comp-footer-app-poweredlogo);
      background-size: contain;
      background-position: 0 0;
      background-repeat: no-repeat;
    }
    .product-logo {
      width: var(--comp-themed-header-logo-width);
      height: var(--comp-themed-header-logo-height);

      background-image: var(--comp-themed-header-logo);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left center;
    }
    @media (min-width: 418px) and (max-width: 898px) {
      margin-left: 50px;
    }
  }

  .eid-menu-toggle {
    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  .eid-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1 auto;

    .eid-menu-item {
      display: none;

      &--home {
        @media screen and (min-width: 700px) {
          display: block;
        }
      }
      &--profile {
        @media screen and (min-width: 800px) {
          display: block;
        }
      }
      &--apps {
        @media screen and (min-width: 900px) {
          display: block;
        }
      }
      &--activity {
        @media screen and (min-width: 800px) {
          display: block;
        }
      }
      &--funds {
        @media screen and (min-width: 1000px) {
          display: block;
        }
      }
      &--dashboard {
        @media screen and (min-width: 800px) {
          display: block;
        }
      }
      &--security {
        @media screen and (min-width: 800px) {
          display: block;
        }
      }
      &--support {
        @media screen and (min-width: 1024px) {
          display: block;
        }
      }
    }
    &-toggle {
      @media (min-width: 418px) and (max-width: 898px) {
        position: absolute;
        left: 20px;
      }
    }
  }
}
