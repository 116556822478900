.t {
  &-right {
    text-align: right;
  }
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
}
.tt {
  &-uppercase {
    text-transform: uppercase;
  }
}

.text {
  &--fs {
    &-14 {
      font-size: 0.875rem;
    }
  }

  &--a {
    &-right {
      text-align: right;
    }
    &-left {
      text-align: left;
    }
    &-center {
      text-align: center;
    }
  }

  &--underline {
    text-decoration: underline;
    font-weight: 600;
  }
}
