.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  &--col-3 {
    .card {
      flex: 0 0 calc((100% - 2.5rem) / 2);
      margin-left: 0;
      margin-bottom: 2.5rem;

      &:first-child {
        margin-left: 0;
      }

      @media screen and (max-width: 991px) {
        &:nth-child(3) {
          flex: 0 0 100%;
        }
      }

      @media screen and (min-width: 992px) {
        flex: 0 0 calc((100% - 5rem) / 3);
        margin-left: 2.5rem;
      }
    }
  }
}
.card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;

  padding: 1rem 1rem 1.5rem 1rem;

  cursor: pointer;

  border-radius: 8px;
  overflow: hidden;

  @include panel();
  @include animate(box-shadow);

  &:hover {
    box-shadow: 0 8px 24px 0 rgba(70, 74, 82, 0.08), 0 4px 12px 0 rgba(70, 74, 82, 0.08),
      0 8px 12px 4px rgba(70, 74, 82, 0.08);
  }

  &__title {
    margin-bottom: 0.625rem;
    font-family: var(--eid-accent-font);
    font-size: 1.25rem;
    line-height: 33px;
    text-align: center;
    letter-spacing: -0.48px;
    color: var(--eid-col-gray);
  }
  &__description {
    min-height: 40px;
    margin-bottom: 2.5rem;
    font-size: 0.875rem;
    line-height: 20px;
    text-align: center;
    color: var(--eid-col-mid-gray);
    &--satrix {
      margin-bottom: 1rem;
    }
  }
  &__image {
    height: 0;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: -1.5rem;
    padding-top: 74%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__content {
    padding: 0.75rem;
    font-size: 0.875rem;
    line-height: 20px;
    ul {
      li {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        margin-top: 0.6rem;

        padding-left: calc(4px + 0.5rem);

        &:first-child {
          margin-top: 0;
        }

        &::before {
          position: absolute;
          top: 8px;
          left: 0;
          display: block;
          content: '';
          flex: 0 0 4px;
          width: 4px;
          min-width: 4px;
          max-width: 4px;
          height: 4px;
          min-height: 4px;
          max-height: 4px;
          margin-right: 0.5rem;

          border-radius: 4px;
          background: var(--eid-col-accent);
        }
        a {
          text-decoration: none;
          color: var(--eid-col-accent);

          &:hover {
            text-decoration: underline;
          }
          &:focus {
            text-decoration: underline;
            box-shadow: none !important;
            outline: none;
          }
        }
      }
    }
  }
  &--no-pointer {
    cursor: unset;
  }
}

