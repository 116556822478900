@include for-handset-only {
  .mat-datepicker-popup {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: 100vh !important;

    .mat-datepicker-content {
      width: 100vw !important;
      height: 100vh !important;

      .mat-calendar {
        width: 100vw !important;
        height: 100vh !important;
      }
    }
  }
}
