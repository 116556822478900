.eid-help {
  --size: 1.25rem;
  width: var(--size);
  height: var(--size);

  margin-left: 0.5rem;

  font-size: var(--size);
  line-height: var(--size);
  vertical-align: middle;

  color: var(--eid-col-mid-gray);

  outline: none;
}
