.profile-type-cards {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  padding-left: 4rem;
  padding-right: 4rem;

  .profile-type-card {
    position: relative;
    margin-bottom: 1rem;
    padding: 1rem 1.5rem 1.5rem 1.5rem;

    background: var(--eid-col-light);
    box-shadow: var(--eid-box-shadow);
    border: 1px solid var(--eid-col-light);

    &__image {
      margin: 0 auto;
      width: 68px;
      height: 68px;

      border-radius: 68px;

      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;

      &.individual {
        background-image: url('../../../assets/theme/easyid/individual.png');
      }
      &.kid {
        background-image: url('../../../assets/theme/easyid/kid.png');
      }
      &.company {
        background-image: url('../../../assets/theme/easyid/company.png');
      }
    }
    &__label {
      font-family: 0.875rem;
      font-weight: 600;
      letter-spacing: -0.34px;
      line-height: 1rem;
      text-align: center;
    }

    .mat-icon {
      transform: scale(0);
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;

      color: var(--eid-col-accent);
    }

    &:active {
      border: 1px solid var(--eid-col-accent);
      .mat-icon {
        transform: scale(1);
      }
    }
  }
}
