.eid-menu-item {
  display: block;

  a,
  button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    height: calc(1rem - 1px);
    margin: 0;
    padding: 0 1.4rem;
    border: none;

    font-family: var(--eid-base-font);
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: none !important;

    color: var(--eid-col-gray);
    background: none;
    outline: none !important;
    cursor: pointer;

    .mat-icon {
      margin-right: 0.35rem;
      color: var(--eid-col-mid-gray);
    }

    &:hover {
      color: var(--eid-col-accent);
    }
    &:focus {
      @include focus(var(--eid-col-accent-focus));
    }
  }
}
