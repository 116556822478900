.eid-country-dropdown {
  .mat-option {
    &.has-underline {
      border-bottom: 1px solid var(--eid-border-color);
    }

    &.mat-active {
      background: var(--eid-col-accent) !important;
      color: var(--eid-col-light) !important;
    }
  }
  .row {
    &--min-height {
      min-height: 90px;
      .mat-auto-form-field {
        padding-bottom: 0.5rem;
      }
    }
  }
}
