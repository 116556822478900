.eid-header-mobile {
  display: block;
  background: var(--comp-header-bg-light);
  color: var(--comp-header-col);
  border-bottom: var(--comp-header-border);

  .row {
    height: var(--comp-header-height);
  }

  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    //margin-right: 70px;

    &__app {
      flex: 0 0 var(--comp-header-app-logo-width);
      min-width: var(--comp-header-app-logo-width);
      height: var(--comp-header-app-logo-height);

      margin-right: 1.5rem;

      background-image: var(--comp-header-app-logo);
      background-position: 0 50%;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &__powered-by {
      flex: 0 0 var(--comp-header-app-poweredlogo-width);
      min-width: var(--comp-header-app-poweredlogo-width);
      height: var(--comp-header-app-poweredlogo-height);

      background-image: var(--comp-header-app-poweredlogo);
      background-size: contain;
      background-position: 0 0;
      background-repeat: no-repeat;
    }
  }
  .product-logo {
    width: var(--comp-themed-header-logo-width);
    height: var(--comp-themed-header-logo-height);

    background-image: var(--comp-header-product-logo);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
  }
}

.eid-themed-header {
  height: 0;
  background: var(--comp-themed-header-background);

  @include animate(height);

  .container {
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
  .logo {
    width: var(--comp-themed-header-logo-width);
    height: var(--comp-themed-header-logo-height);

    background-image: var(--comp-themed-header-logo);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
  }
}

// var(--comp-themed-header-height)

html[class*='product--'] {
  .eid-themed-header {
    height: var(--comp-themed-header-height);
  }
}
