:root {
  .eid-collapsed-menu {
    overflow: visible;
    box-shadow: 0px 0px 10px rgba(70, 74, 82, 0.2);
    &::before {
      background-color: #fff;
      content: '';
      display: block;
      position: relative;
      width: 16px;
      z-index: 99;
      height: 16px;
      top: -16px;
      transform: rotate(45deg);
      left: 48%;
      box-shadow: -2px -2px 4px -2px rgba(0, 0, 0, 0.2);
    }
  }

  .eid-current-user-panel {
    overflow: visible;
    box-shadow: 0px 0px 10px rgba(70, 74, 82, 0.2);
    &::before {
      background-color: #fff;
      content: '';
      display: block;
      position: relative;
      width: 16px;
      z-index: 99;
      height: 16px;
      top: -8px;
      transform: rotate(45deg);
      left: 81%;
      box-shadow: -2px -2px 4px -2px rgba(0, 0, 0, 0.2);
    }
  }
}

/*
  List of icons that is different for each theme.
*/
$theme-icons: failed, documentation, verification;

@mixin setup-theme-icons($assetsUrl) {
  @each $icon in $theme-icons {
    .theme-icon-#{$icon} {
      content: url('#{$assetsUrl}/#{$icon}.svg');
    }
  }
}

/* --- DISABLE ZOOM ON IPHONE ---*/
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
  input[type='text']:focus,
  input[type='password']:focus,
  textarea:focus,
  select:focus {
    font-size: 16px;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}
