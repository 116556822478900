.products {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 3rem;

  @include for-handset-up {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem 2.5rem;
    justify-items: center;

    &--minors {
      display: flex;
      flex-direction: row;
      justify-content: center;

      margin-bottom: 0;
      padding: 0 2.5rem 3rem 2.5rem;
      @media screen and (min-width: 1000px) {
        grid-template-columns: 0;
        grid-gap: 0 !important;
        padding: 0;
      }
    }
  }
  @media screen and (min-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5rem 2.5rem;
    padding: 0;
  }
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  max-width: 80%;

  padding: 1rem 20px 0 20px;
  margin-top: 1rem;

  background: var(--eid-col-light);
  box-shadow: var(--eid-box-shadow);

  cursor: pointer;

  @media (min-width: 280px) and (max-width: 319px) {
    max-width: 100%;
  }

  @include for-handset-up {
    max-width: none;
    margin-top: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &__name {
    margin-bottom: 0.625rem;
    height: 33px;

    font-family: var(--eid-accent-font);
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 33px;
    text-align: center;

    color: var(--eid-base-text-col);
  }
  &__description {
    max-height: 158px;
    height: 158px;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;

    color: var(--eid-col-mid-gray);

    @media (min-width: 280px) and (max-width: 319px) {
      font-size: 0.75rem;
    }
  }
  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 76px;

    border-top: 1px solid var(--eid-border-color);

    img {
      display: block;
      width: auto;
      height: 27px;
    }
  }

  &__image {
    margin: -1rem -20px 1rem -20px;
    object-fit: cover;
    @media screen and (max-width: 999px) {
      height: 33vw;
    }
    @media screen and (min-width: 1000px) {
      width: 117%;
    }
  }

  &--Mrphy {
    .product__logo {
      img {
        height: 36px;
      }
    }
  }
}
