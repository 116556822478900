// .mt-1 {
//   margin-top: 1rem;
// }
// .mt-2 {
//   margin-top: 1.5rem;
// }
// .mt-3 {
//   margin-top: 2rem;
// }

.ws {
  &-nowrap {
    white-space: nowrap;
  }
}

.m {
  &t {
    &-0 {
      margin-top: 0 !important;
    }
    &-1 {
      margin-top: 1rem !important;
    }
    &-2 {
      margin-top: 1.5rem !important;
    }
    &-3 {
      margin-top: 2rem !important;
    }
    &-4 {
      margin-top: 2.5rem !important;
    }
  }
  &b {
    &-0 {
      margin-bottom: 0 !important;
    }
    &-1 {
      margin-bottom: 1rem !important;
    }
    &-2 {
      margin-bottom: 1.5rem !important;
    }
    &-3 {
      margin-bottom: 2rem !important;
    }
    &-4 {
      margin-bottom: 2.5rem !important;
    }
  }
  &l {
    &-0 {
      margin-left: 0 !important;
    }
    &-1 {
      margin-left: 1rem !important;
    }
    &-2 {
      margin-left: 1.5rem !important;
    }
    &-3 {
      margin-left: 2rem !important;
    }
    &-4 {
      margin-left: 2.5rem !important;
    }
  }
  &r {
    &-0 {
      margin-right: 0 !important;
    }
    &-1 {
      margin-right: 1rem !important;
    }
    &-2 {
      margin-right: 1.5rem !important;
    }
    &-3 {
      margin-right: 2rem !important;
    }
    &-4 {
      margin-right: 2.5rem !important;
    }
  }
}
.p {
  &t {
    &-0 {
      padding-top: 0 !important;
    }
    &-1 {
      padding-top: 1rem !important;
    }
    &-2 {
      padding-top: 1.5rem !important;
    }
    &-3 {
      padding-top: 2rem !important;
    }
    &-4 {
      padding-top: 2.5rem !important;
    }
  }
  &b {
    &-0 {
      padding-bottom: 0 !important;
    }
    &-1 {
      padding-bottom: 1rem !important;
    }
    &-2 {
      padding-bottom: 1.5rem !important;
    }
    &-3 {
      padding-bottom: 2rem !important;
    }
    &-4 {
      padding-bottom: 2.5rem !important;
    }
    &-5 {
      padding-bottom: 3rem !important;
    }
    &-6 {
      padding-bottom: 3.5rem !important;
    }
  }
  &l {
    &-0 {
      padding-left: 0 !important;
    }
    &-1 {
      padding-left: 1rem !important;
    }
    &-2 {
      padding-left: 1.5rem !important;
    }
    &-3 {
      padding-left: 2rem !important;
    }
    &-4 {
      padding-left: 2.5rem !important;
    }
  }
  &r {
    &-0 {
      padding-right: 0 !important;
    }
    &-1 {
      padding-right: 1rem !important;
    }
    &-2 {
      padding-right: 1.5rem !important;
    }
    &-3 {
      padding-right: 2rem !important;
    }
    &-4 {
      padding-right: 2.5rem !important;
    }
  }
}

.mw {
  &--80 {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  &--60 {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
