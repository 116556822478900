.mat-table {
  tbody {
    box-shadow: 0px 0px 10px rgba(70, 74, 82, 0.2);
    position: relative;
    top: 10px;

    border-collapse: collapse;
    border-radius: var(--eid-max-border-radius);
    border-style: hidden;
    background: none;
  }
}
