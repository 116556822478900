.eid-collapsed-menu {
  &__item {
    padding-right: 1.5rem !important;
    text-decoration: none !important;
    &--apps {
      @media screen and (max-width: 900px) {
        display: flex !important;
      }
    }
  }
  &__item {
    display: none !important;
    &--home {
      @media screen and (max-width: 700px) {
        display: block !important;
      }
    }
    &--profile {
      @media screen and (max-width: 960px) {
        display: block !important;
      }
    }
    &--apps {
      @media screen and (max-width: 900px) {
        display: block !important;
      }
    }
    &--funds {
      @media screen and (max-width: 1000px) {
        display: block !important;
      }
    }
    &--security {
      @media screen and (max-width: 1200px) {
        display: block !important;
      }
    }
    &--support {
      @media screen and (max-width: 1300px) {
        display: block !important;
      }
    }
  }
}
