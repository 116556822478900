.selector-overlay {
  width: 100vw;
  height: calc(100% - 60px);
  margin-top: 60px;

  .mat-dialog-container {
    padding: 0;

    box-shadow: none;
    border-radius: 0;
  }

  &--xlight-gray {
    background: var(--eid-col-xlight-gray);
  }
}

.custom-selector-overlay {
  width: 100vw;
  height: calc(100% - 55px);
  margin-top: 55px;

  .mat-dialog-container {
    padding: 0;

    box-shadow: none;
    border-radius: 0;
  }

  &--xlight-gray {
    background: var(--eid-col-xlight-gray);
  }
}

.custom-intro-overlay {
  width: 100vw;
  height: 100%;
  margin: 0 auto;

  .mat-dialog-container {
    padding: 145px 24px 24px 24px;
    background: rgba(0, 0, 0, 0.45);
  }

  &--xlight-gray {
    background: var(--eid-col-xlight-gray);
  }
}

.custom-intro-mobile-overlay {
  width: 100vw;
  height: 100%;
  margin: 0 auto;

  .mat-dialog-container {
    padding: 0px;
    background: rgba(0, 0, 0, 0.45);
    border-radius: 0px;
  }

  &--xlight-gray {
    background: var(--eid-col-xlight-gray);
  }
}

.select-list-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  width: 100%;
  height: 100%;

  .filter {
    position: relative;
    display: flex;
    flex: 0 0 48px;

    input {
      flex: 1 1 auto;
      height: 48px;
      padding: 0 1rem;
      border: none;
      border-bottom: 1px solid var(--eid-border-color);
      border-radius: 0;

      outline: none;

      font-family: var(--eid-base-font);
      font-size: 1rem;
      color: var(--eid-base-text-col);
    }
    .mat-icon {
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translate(50%, -50%);
    }
  }
}

.selector-list {
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;

  width: 100%;
  background: var(--eid-col-xlight-gray);

  &__header {
    position: sticky;
    top: 0;
    height: 1.5rem;
    padding: 0 1rem;
    line-height: 1.5rem;

    background: var(--eid-col-xlight-gray);

    font-size: 1rem;
    font-weight: 600;
  }
  &__items {
    list-style: none;
    margin: 0;
    padding: 0;
    background: var(--eid-col-light);

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      width: 100%;
      padding: 1rem;

      font-size: 1rem;
      color: var(--eid-col-mid-gray);

      @media (min-width: 280px) and (max-width: 319px) {
        font-size: 0.875rem;
      }
    }
  }
}
.selector-list-pagination {
  position: absolute;
  top: 102px;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 2rem;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    font-size: 0.6875rem;
    font-weight: 600;
    line-height: 0.8125rem;
    color: var(--eid-col-accent);
    width: 100%;
    min-height: 1.375rem;
    text-align: center;
    flex: 1 1 auto;

    &.active {
      transform: scale(2);
      margin-bottom: 0.5rem;
    }
  }
}
