.mobile-input-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &__group {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    padding: 0 1rem;

    .prefix {
      order: 1;
    }
    .postfix {
      order: 2;
    }

    .mat-icon {
      width: 20px;
      height: 20px;
      font-size: 20px;
      color: var(--eid-col-mid-gray);
      margin-inline-end: 1rem;
    }

    .input {
      position: relative;
      order: 2;

      flex: 1 1 auto;
      width: auto;
      height: 50px;

      label {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;

        order: 0;

        width: 100%;
        height: 50px;

        line-height: 50px;
      }
      input {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 50px;

        border: none;

        font-size: 1rem;
        font-family: var(--eid-base-font);

        outline: none;
      }
    }
  }
  &__meta {
    width: calc(100% - 2rem);
    margin-inline-start: 1rem;
    margin-inline-end: 1rem;
    .hint {
      font-size: 0.75rem;
      color: var(--eid-col-mid-gray);
      line-height: 1rem;
      padding-block-end: 0.25rem;
      border-block-end: 1px solid var(--eid-border-color);
    }
    .validation {
      font-size: 0.75rem;
      color: var(--eid-col-error);
      line-height: 1rem;
      padding-block-end: 0.25rem;
      border-block-end: 1px solid var(--eid-border-color);
    }
  }
}
